import React, { Suspense } from "react";
import styled from "styled-components";
import { useStore } from "./store";
//import { Transition } from 'react-gasp';
//import { motion } from 'framer-motion';
import { /*_cameras,*/ bg } from "./store/MockStore";
import { Routes, Route } from "react-router-dom";
//import { useQuery } from "react-query";
import "./App.css";
import { randomItem } from "@utils";
import StyledContainer from "@muiux/Container";
import Hero from "@muiux/Hero";
import { Grid, createTheme } from "@mui/material";
import NavigationColumn from "@screens/templates/navigation";

export const AppContainer = styled.div`
  display: flex;
  background-color: ${randomItem(bg)};
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100vw;
  height: 100vh;
`;

export const theme = createTheme();

const Home = React.lazy(() => import("@screens/Home"));
const DetailPage = React.lazy(() => import("@screens/detail"));
const IndexPage = React.lazy(() => import("@screens/main"));

function App() {
  if (window.location.pathname.includes("experimental")) {
    return (
      <AppContainer id="SC9App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/experimental" element={<Home />} />
        </Routes>
      </AppContainer>
    );
  }
  
  return (
    <StyledContainer style={{ padding: "0px" }}>  
      <Hero />
      <StyledContainer maxWidth="lg">
        <Grid container spacing={2}>
          <NavigationColumn />
          <Grid item xs={12} md={8}>
            <Suspense fallback={<div></div>}>
              <Routes>
                <Route path="/" element={<IndexPage />} />
                <Route path="/project-detail/:title" element={<DetailPage />} />
                <Route path="/experimental" element={<Home />} />
              </Routes>
            </Suspense>
          </Grid>
        </Grid>
      </StyledContainer>
    </StyledContainer>
  );
}

export default App;
