import React, { useEffect, useRef } from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";
import { Tween, Timeline } from "react-gsap";
import AnimatedLogo from "./AnimatedLogo";

const Content = styled.div`
  display: flex;
  height: 100vh;
`;

interface AnimatedTextProps {
  text: any;
  text2: string;
  cssClassName?: string;
}

const AnimatedText: React.FC<AnimatedTextProps> = ({
  text,
  text2,
  cssClassName = "waveContent",
}) => {
  return (
    <Content className={cssClassName}>
      <Typography variant="h4">{text}</Typography>
      <Typography variant="h4">{text}</Typography>
      <Typography variant="h5">
        {text2}
        <AnimatedLogo />
      </Typography>
      <Typography variant="h5">{text2}</Typography>
    </Content>
  );
};

export default AnimatedText;
