/* TODO:
- Format should support types that can relate to walls, planes, special items, etc
*/
import React, {useEffect, useState } from "react";
import isMobile from "is-mobile";
import * as BABYLON from "@babylonjs/core";
import { Inspector } from "@babylonjs/inspector";
import { useStore } from "@store";
import {
  Mesh,
  Vector3,
  Color3,
  StandardMaterial,
  FreeCamera,
} from "@babylonjs/core";
import { useScene } from "react-babylonjs";
import globals from "@utils/globals";
import { enableVRIcon } from "./utilities";
import { mobileVRCameraResponsiveFreeCam } from "../Helpers/Cameras";
import MapDisplay from "./MapDisplay";
import { keys } from "./Maps/Keys";
import { map0 } from "./Maps/Map";
import { createDoorLockText } from "./Maps/utils";
import { START_DELAY } from "@hooks/usePixi";
const { isDebug } = globals;
const unitsize = 40;
let time = 0;

interface MapProps {
  map?: number[][];
  setDisableLoader: any;
  enableCover: any;
  screenOverlay: any;
  mapKeys?: {
    [key: number]: {
      texture?: string;
      displayMapColor?: string;
      name?: string;
      exe?: any;
      init?: any;
      checkCollisions?: boolean;
    };
  };
  camera?: FreeCamera;
  ground?: any;
}

const Map: React.FC<MapProps> = ({
  map,
  mapKeys,
  camera,
  ground,
  setDisableLoader,
  enableCover,
  screenOverlay,
}) => {
  const scene: any | null = useScene();
  const store = useStore();

  const [hasInit, setHasInit] = useState(false);
  const initializeMapKeys = () => {
    if (!scene && !store.fpSprites.length && !store.fpModels.length) return;
    const wallSize = unitsize / 3;
    const mapW = map.length;
    const mapH = map[0].length;

    if (isDebug) {
      (window as any).scene = scene;
    }
    for (let i = scene.meshes.length - 1; i >= 0; i--) {
      var mesh: any = scene.meshes[i];
      if (
        !mesh?.tag?.includes("saved") &&
        !mesh.name.includes("__root__") &&
        !mesh.name.includes("Mesh")
      ) {
        mesh?.dispose();
      }
    }

    if (store.fpSprites.length && store.fpModels.length) {
      for (let i = 0; i < mapW; i++) {
        for (let j = 0; j < mapH; j++) {
          const mapKey = map[i][j];
          if (mapKeys[mapKey]?.init) {
            mapKeys[mapKey]?.init(
              map,
              i,
              j,
              unitsize,
              scene,
              mapKeys,
              mapW,
              wallSize,
              mapH,
              store,
              enableCover
            );
          }
        }
      }
      createDoorLockText();
      enableVRIcon("none");
      setTimeout(() => setDisableLoader(true), START_DELAY);
    }
  };

  useEffect(() => {
    if (!scene) {
      return;
    } else {
      globals.getAllGlbModels(store, scene);
    }
  }, [scene]);

  useEffect(() => {
    if (!scene) return;
    const boxCloud = scene.getMeshByName("boxCloud");
    if ((boxCloud && !screenOverlay) || (store.renderPixi && boxCloud)) {
      boxCloud.material.diffuseTexture.isEnabled = false;
      enableCover(true);
    }

    if (
      (!screenOverlay && !scene.getMeshByName("boxCloud")) ||
      !store.renderPixi
    ) {
      const enableCloudBox = () => {
        const boxCloud: any = Mesh.CreateSphere("boxCloud", 100, 1000, scene);
        boxCloud.tag = "saved";
        boxCloud.position = new Vector3(0, 0, 0);
        let cloudMaterial = new StandardMaterial("cloudMat", scene);
        let cloudProcText = new BABYLON.CustomProceduralTexture(
          "seaCloudsTexture",
          "clouds",
          512,
          scene
        );
        cloudProcText.setVector2(
          "resolution",
          new BABYLON.Vector2(window.innerWidth, window.innerHeight)
        );
        cloudMaterial.diffuseTexture = cloudProcText;
        cloudMaterial.backFaceCulling = false;
        cloudMaterial.diffuseTexture.coordinatesMode =
          BABYLON.Texture.SKYBOX_MODE;
        boxCloud.material = cloudMaterial;
        scene.onBeforeRenderObservable.add((scene) => {
          if (scene !== null && cloudProcText !== null) {
            time += scene.getEngine().getDeltaTime();
            // 'time' is a uniform on the shader
            cloudProcText.setFloat("time", time);
          }
        });
      };
      // open cloud box after pixel filter

      setTimeout(() => enableCloudBox(), 2500);
    }
  }, [scene, store.renderPixi, screenOverlay]);

  useEffect(() => {
    if (!scene) return;
    if (!hasInit) {
      setHasInit(true);
      mobileVRCameraResponsiveFreeCam(scene, camera, store.setEnableRoot);
    }

    if (isDebug) {
      Inspector.Show(scene, {
        embedMode: true,
      });
    }
    //enableCover(true);
    if (!ground && !store.fpSprites.length) {
      //globals.getAllGlbModels(store, scene)
      globals.setUpAllSprites(store, scene);
      ground = Mesh.CreatePlane("ground", 10 * (unitsize * map.length), scene);
      ground.tag = "saved";
      ground.material = new StandardMaterial("groundMat", scene);
      (ground.material as any).diffuseColor = new Color3(1, 0.5, 1);
      ground.material.backFaceCulling = false;
      ground.material.alpha = 0;
      ground.position = new Vector3(0, -14, 0);
      ground.rotation = new Vector3(Math.PI / 2, 0, 0);
      ground.checkCollisions = true;
    } else {
      initializeMapKeys();
      //enableCover(false);
      /*
      setTimeout(() => {

        enableCover(false)
      }, 4000)
      */
    }
  }, [scene, store.fpSprites, ground, hasInit, map, store.fpModels]);

  if (!hasInit) {
    return;
  }
  // if potrait hide map display
  if (window.innerHeight > window.innerWidth) {
    return null;
  }
  return (
    <>
      {!store.renderPixi && (
        <MapDisplay
          map={map}
          mapKeys={mapKeys}
          unitsize={unitsize}
          tilesize={10}
        />
      )}
    </>
  );
};

Map.defaultProps = {
  map: map0,
  mapKeys: keys,
};

export default Map;
