// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.\\---------dev-sharedUiComponents-dist-components-lines-OptionsLineComponent-modules__optionsLine {
  font-family: "acumin-pro-condensed", sans-serif;
  font-size: 12px;
  height: 25px;
  padding-left: 15px;
}

.\\---------dev-sharedUiComponents-dist-components-lines-OptionsLineComponent-modules__optionsSelect {
  width: 100%;
  height: 100%;
  border: unset;
}`, "",{"version":3,"sources":["webpack://./../../../dev/sharedUiComponents/dist/components/lines/OptionsLineComponent.modules.scss"],"names":[],"mappings":"AAAA;EACI,+CAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,aAAA;AACJ","sourcesContent":[".optionsLine {\r\n    font-family: \"acumin-pro-condensed\", sans-serif;\r\n    font-size: 12px;\r\n    height: 25px;\r\n    padding-left: 15px;\r\n}\r\n\r\n.optionsSelect {\r\n    width: 100%;\r\n    height: 100%;\r\n    border: unset;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionsLine": `---------dev-sharedUiComponents-dist-components-lines-OptionsLineComponent-modules__optionsLine`,
	"optionsSelect": `---------dev-sharedUiComponents-dist-components-lines-OptionsLineComponent-modules__optionsSelect`
};
export default ___CSS_LOADER_EXPORT___;
