// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#log-console {
  background: #333333;
  height: 120px;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
}
#log-console .log {
  color: white;
  font-size: 14px;
  font-family: "Courier New", Courier, monospace;
}
#log-console .log.error {
  color: red;
}`, "",{"version":3,"sources":["webpack://./../../../tools/guiEditor/dist/components/log/log.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;EACA,WAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AACI;EACI,YAAA;EACA,eAAA;EACA,8CAAA;AACR;AACQ;EACI,UAAA;AACZ","sourcesContent":["#log-console {\r\n    background: #333333;\r\n    height: 120px;\r\n    box-sizing: border-box;\r\n    margin: 0;\r\n    padding: 10px;\r\n    width: 100%;\r\n    overflow: hidden;\r\n    overflow-y: auto;\r\n\r\n    .log {\r\n        color: white;\r\n        font-size: 14px;\r\n        font-family: \"Courier New\", Courier, monospace;\r\n\r\n        &.error {\r\n            color: red;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
