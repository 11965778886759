import React from "react";
import styled from "styled-components";
import ModalContainer, { ReactPortal } from "@ui/GS/ModalWindow";
import { Tween, Timeline } from "react-gsap";
import CV from "@ui/svg/nav/cv";
import LinkedIn from "@ui/svg/nav/linkedin";
import GitHub from "@ui/svg/nav/github";
import FlipBoard from "@ui/svg/nav/flipboard";
import hubs from "@ui/svg/nav/hubs";

const NavContainer = styled.div`
  width: 160px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin: auto;
  @media screen and (orientation: portrait) {
    width: 60vw;
  }
  @media screen and (orientation: landscape) {
    width: 260px;
  }
  @media (min-width: 1025px) {
    width: 260px;
  }
`;

const NavLink = styled.a`
  display: flex;
  padding: 5px;
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  &:hover, :active: {
    text-decoration: none;
  }
`;

const NavImg = styled.div`
  height: 8.5vw;
  width: 8.5vw;
  padding: 10px;
  transition: all 0.9ms ease-in;
  &:svg #mainPath: {
    fill: #000066;
  }
  @media screen and (orientation: landscape) {
    height: 40px;
    width: 40px;
  }
  @media (min-width: 1025px) {
    height: 40px;
    width: 40px;
  }
  &:hover,
  :active {
    transform: scale(1.2);
  }
  &:hover {
    fill-rule: evenodd;
  }
`;

const NavModalMenuContainer = styled.div`
  position:absolute;
  top:0;
  right:0;
  bottom: 0;
  left:0;
  padding 20%;
  display:flex;
  justify-content:center;
  align-items: center;
`;

const NavModalMenuInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const H1Link = styled.h1`
  color: white;
  font-size: 2.5rem;
  text-decoration: none;
`;

const NavModalMenu = (props: any) => {
  return (
    <NavModalMenuContainer>
      <NavModalMenuInnerContainer>
        <FlexRowCenter>
          <NavLink
            href="https://flipboard.com/@seacloud9/interactive-news-that-inspires-creative-engineers-bpgud1suy"
            target="_blank"
          >
            <NavImg
              dangerouslySetInnerHTML={{ __html: FlipBoard("#FFFFFF") }}
            />
            <H1Link>Flipboard</H1Link>
          </NavLink>
          <NavLink href="https://github.com/seacloud9" target="_blank">
            <NavImg dangerouslySetInnerHTML={{ __html: GitHub("#FFFFFF") }} />
            <H1Link>GitHub</H1Link>
          </NavLink>
          <NavLink
            href="https://www.linkedin.com/in/brendonsmith/"
            target="_blank"
          >
            <NavImg dangerouslySetInnerHTML={{ __html: LinkedIn("#FFFFFF") }} />
            <H1Link>LinkedIn</H1Link>
          </NavLink>
          <NavLink href="/Brendon-Smith-Resume.pdf" target="_blank">
            <NavImg dangerouslySetInnerHTML={{ __html: CV("#FFFFFF") }} />
            <H1Link>Resume</H1Link>
          </NavLink>
        </FlexRowCenter>
      </NavModalMenuInnerContainer>
    </NavModalMenuContainer>
  );
};

const NavModal = (props: any) => {
  return (
    <ModalContainer hasFab>
      <NavModalMenu />
    </ModalContainer>
  );
};

export default NavModal;
