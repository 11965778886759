import {
  AppBar,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { theme } from "../../../App";

const Modal = ({
  fullScreen,
  open,
  handleClose,
  title,
  children,
  style,
}: any) => {
  const isMedium = fullScreen;
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      style={style}
    >
      <DialogTitle
        id="responsive-dialog-title"
        sx={{ paddingBottom: 0, backgroundColor: "#f4f4f1" }}
      >
        {isMedium && (
          <AppBar sx={{ position: "relative", borderRadius: "24px" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {title.replaceAll("-", " ")}
              </Typography>
            </Toolbar>
          </AppBar>
        )}
        {!isMedium && title.replaceAll("-", " ")}
      </DialogTitle>
      <DialogContent style={{ backgroundColor: "#f4f4f1" }}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
