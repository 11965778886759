import { useEffect, useRef, useState } from "react";
import { useScene } from "react-babylonjs";
import * as BABYLON from "@babylonjs/core";
import * as BABYLONGUI from "@babylonjs/gui";
import * as PIXI from "pixi.js";
import { Container, createRoot, Graphics } from "@pixi/react";
import { useStore } from "@store";
import debounce from "debounce";
import { MapPixiScenes } from "../components/Pixi";
import { stereoShader } from "../components/Pixi/Filters/StereoShader";
import ErrorBoundary from "@screens/ErrorBoundary";
import { cameraLevelPosition, modelsToLoad } from "@utils/globals";
let disableRender = false;
let pixiRefs;
let root;
let engine;
export const START_DELAY = 2000;

let engineProperties = {
  stageWidth: null,
  stageHeight: null,
  splashSizeRatio: null,
  splashWidth: null,
  splashHeight: null,
  splashPxSize: null,
  splashFontWidth: null,
};

const RenderNoVr = (scene, engine) => {
  scene.render();
  engine.wipeCaches(true);
  pixiRefs.current.renderer.reset();
  pixiRefs.current.renderer.render(pixiRefs.current.stage, { clear: false });
};

const renderVRPixiPlane = (scene, engine) => {
  let camera = scene.activeCamera;
  // Create a quad (plane) for displaying the PixiJS content
  const quad = BABYLON.MeshBuilder.CreatePlane(
    "quad",
    { width: 2, height: 1 },
    scene
  );

  // Position the quad in front of the camera
  quad.position = camera.position.add(
    camera.getDirection(BABYLON.Vector3.Forward()).scale(2)
  ); // Adjust the distance as needed

  // Rotate the quad to face the camera
  quad.rotation = camera.rotation.clone();

  // Use the camera's position and rotation for the quad
  camera.onViewMatrixChangedObservable.add(() => {
    quad.position = camera.position.add(
      camera.getDirection(BABYLON.Vector3.Forward()).scale(2)
    ); // Update position
    quad.rotation = camera.rotation.clone(); // Update rotation
  });

  // Register an onBeforeRenderObservable to update PixiJS content
  scene.onBeforeRenderObservable.add(() => {
    // Render PixiJS content to a Babylon.js render target texture
    //const renderTargetTexture = pixiRefs.current.renderer.extract.canvas(); // Adjust as needed

    pixiRefs.current.renderer.reset();
    pixiRefs.current.renderer.render(pixiRefs.current.stage, { clear: true });

    // Apply the render target texture to a Babylon.js material
    const material = new BABYLON.StandardMaterial("pixiMaterial", scene);
    //material.diffuseTexture = new BABYLON.Texture(renderTargetTexture, scene);
    material.diffuseTexture = new BABYLON.Texture(
      pixiRefs.current.renderer.view.toDataURL(),
      scene
    );
    //

    // Apply the material to the quad
    quad.material = material;
  });
};

const RenderVr = (scene, engine) => {
  // Render the Babylon.js VR scene
  scene.render();

  // Wipe caches if necessary (optional)
  engine.wipeCaches(true);
};

function createPixiRefs(options) {
  return {
    renderer: new PIXI.Renderer(options),
    stage: new PIXI.Container(),
  };
}

export const usePixiData = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const renderErrorRef = useRef(null);
  const {
    gameData,
    setEnableRoot,
    enabledRoot,
    renderPixi,
    setRenderPixi,
    setDisableLoader,
    setRenderInc,
    renderInc,
    fpModels,
  } = useStore();
  const scene = useScene();
  const currentPixiRef = useRef(pixiRefs?.current);
  const rootPixi = useRef(root);
  const enableScene = () => {
    if (renderPixi) {
      rootPixi.current = createRoot(pixiRefs.current.stage);
      return rootPixi;
    }
  };
  const resetEngineProperties = (): any => {
    const stageWidth = engine.getRenderWidth();
    const stageHeight = engine.getRenderHeight();
    const splashFontWidth = engine.getRenderWidth() * 0.03;
    const splashSizeRatio = 0.8;
    const splashWidth = (stageWidth * splashSizeRatio) / stageWidth;
    const splashHeight = (stageHeight * splashSizeRatio) / stageHeight;
    const originalSplashSize = 1024;
    const splashPxSize = splashSizeRatio * originalSplashSize;
    return (engineProperties = {
      stageWidth,
      splashSizeRatio,
      splashWidth,
      splashHeight,
      splashPxSize,
      stageHeight,
      splashFontWidth,
    });
  };

  const wipeShaders = () => {
    console.log("wipeShaders");
    (window as any)?.gsapTweens.map((tween) => tween.kill());
    pixiRefs.current.renderer.reset();
    pixiRefs.current.renderer.clear();
    scene.render();
    engine.wipeCaches(true);
  };
  const initScene = () => {
    engine = scene.getEngine();
    if (engine._gl) {
      engine._gl.canvas.addEventListener(
        "webglcontextlost",
        (e) => {
          disableRender = true;
        },
        false
      );
      engine._gl.canvas.addEventListener(
        "webglcontextrestored",
        (event) => {
          disableRender = false;
        },
        false
      );

      const options = {
        //autoStart: false,
        clearBeforeRender: false,
        backgroundAlpha: 0,
        preserveDrawingBuffer: true,
        context: engine._gl, // ._gl is public **hidden**
        height: engine.getRenderHeight(),
        roundPixels: true,
        view: engine.getRenderingCanvas(),
        width: engine.getRenderWidth(),
      };

      if (!pixiRefs && !currentPixiRef.current) {
        currentPixiRef.current = createPixiRefs(options);
        pixiRefs = currentPixiRef;
        //currentPixiRef.current = pixiRefs
        setIsInitialized(true);
      }
    }
  };

  const renderRoot = () => {
    rootPixi.current.render(
      engineProperties.splashWidth && (
        <ErrorBoundary>
          <Container ref={renderErrorRef}>
            {MapPixiScenes[`PixiSceneContainer${gameData.level}`]({
              setRenderPixi,
              setDisableLoader,
              resetEngineProperties,
              engineProperties,
              wipeShaders,
            })}
          </Container>
        </ErrorBoundary>
      )
    );
  };

  const resetEngineRenderRoot = () => {
    (window as any)?.gsapTweens.map((tween) => tween.kill());
    let inc = renderInc + 1;
    setRenderInc(inc);
    const engine = scene.getEngine();
    pixiRefs.current.renderer.resize(
      engine.getRenderWidth(),
      engine.getRenderHeight()
    );
    resetEngineProperties();
    rootPixi.current.unmount();
    renderRoot();
  };

  const resizePixiJS = () =>
    debounce(() => {
      resetEngineRenderRoot();
    }, 200)();

  const addResize = () => {
    window.addEventListener("resize", resizePixiJS);
  };

  const removeResize = () => {
    window.removeEventListener("resize", resizePixiJS);
  };

  const enableStereoView = (scene, engine) => {
    const fullScreenSprite = new PIXI.Sprite(PIXI.Texture.EMPTY);
    fullScreenSprite.width = engine.getRenderWidth(); // Use app.screen.width to get the canvas width
    fullScreenSprite.height = engine.getRenderHeight();
    //fullScreenSprite.filters = [stereoShader];
    currentPixiRef.current?.stage.addChild(fullScreenSprite);
  };

  useEffect(() => {
    (window as any).renderPixi = renderPixi;

    const renderFunction = () => {
      if ((window as any).renderPixi) {
        if (scene && scene.activeCamera.id === "webxr") {
          //RenderVr(scene, engine);
        } else {
          RenderNoVr(scene, engine);
        }
        scene.activeCamera.position = new BABYLON.Vector3(
          ...cameraLevelPosition[gameData.level]
        );
      } else {
        /*if (!currentPixiRef.current?.stage) {
          renderRoot();
        }*/
        wipeShaders();
        /*
        (window as any)?.gsapTweens.map((tween) => tween.kill());
        pixiRefs.current.renderer.reset();
        pixiRefs.current.renderer.clear();
        scene.render();
        engine.wipeCaches(true);
        */
      }
    };

    if (
      currentPixiRef.current?.stage &&
      !enabledRoot &&
      scene &&
      scene.activeCamera
    ) {
      enableScene();
      setEnableRoot(true);
      setTimeout(() => {
        // NEEDED TO TRANSITION TO PIXIJS
        resetEngineRenderRoot();
      }, START_DELAY);
    }

    if (
      currentPixiRef.current?.stage &&
      engine &&
      scene &&
      scene.activeCamera
    ) {
      engine.runRenderLoop(renderFunction);
    }
  }, [enabledRoot, currentPixiRef.current?.stage, renderPixi]);

  return {
    scene,
    pixiRefs: currentPixiRef,
    initScene,
    engine,
    resetEngineProperties,
    removeResize,
    addResize,
    setRenderPixi,
  };
};
