import { Color3, PointLight, Sprite, SpriteManager, StandardMaterial, Texture, Vector3, Animation, PointerEventTypes } from "@babylonjs/core";
import * as BABYLONGUI from "@babylonjs/gui";
import { randomItem } from "@utils";
import { levelUp } from "../utilities";

/*
These const below map to sprite sheet data 
*/
export const skullDoor = [54]
export const keyTextures = [27];
export const celingTextures = [30, 31, 32, 92, 95, 97];
export const floorTextures = [92, 95, 97];
export const wallTexture = [61, 60, 57, 69, 65, 81, 82, 90, 96, 98];
export const glassTexture = [9];
export const doorTextures = [18, 19, 20, 21, 22] 
export const redWalls = [53]
export const crystals = [52]



export const randomCubeWall = () => randomItem(wallTexture);
export const longWall = (size) =>
  new Array(size).fill(null).map(() => randomCubeWall());
let lockDoorGUI;  
let textBlockDoor;

export const defaultLightingOptions = {
    direction: new Vector3(0, -1, 0),
    angle: Math.PI / 2,
    exponent: 2,
  };  

export const dungeonLighting = [
    {
      diffuse: new Color3(0.02, 0.49, 0.23),
      specular: new Color3(0, 1, 0),
      options: {
        ...defaultLightingOptions,
      },
    },
    {
      diffuse: new Color3(0.02, 0.18, 0.49),
      specular: new Color3(0, 1, 1),
      options: {
        ...defaultLightingOptions,
      },
    },
    {
      diffuse: new Color3(0.49, 0.02, 0.33),
      specular: new Color3(1, 0, 0.83),
      options: {
        ...defaultLightingOptions,
      },
    },
    {
      diffuse: new Color3(0.49, 0.02, 0.14),
      specular: new Color3(1, 0, 0),
      options: {
        ...defaultLightingOptions,
      },
    },
  ]; 

  export const getLights = (
    {
      dungeonLighting,
      position = new Vector3(-1, 1, -1),
      name = "spotLight",
    },
    scene
  ) => {
    const randomLight = randomItem(dungeonLighting);
    //console.log('getLIghts:', name, position, randomLight.options.direction, randomLight.options.angle, randomLight.options.exponent)
    const light = new PointLight(
      `${name}-${Math.random()}`,
      position,
      scene
    );
    //const light = new BABYLON.SpotLight(name, position, position, randomLight.options.angle, randomLight.options.exponent, scene);
    light.diffuse = randomLight.diffuse;
    light.specular = randomLight.specular;
  };

  export const constructFloorAndSky = (
    planes: any,
    scene: any,
    textures: any,
    mapW: any,
    mapH: any,
    i: any,
    j: any,
    unitsize:any,
  ) => {
    planes.bottom.material = new StandardMaterial(`MatBottom${i}`, scene);
    planes.top.material = new StandardMaterial(`MatTop${i}`, scene);
    const texture1 = new Texture(textures.bottom, scene);
    const texture2 = new Texture(textures.top, scene);
    texture1.hasAlpha = true;
    texture2.hasAlpha = true;
    (planes.bottom.material as any).diffuseTexture = texture1;
    (planes.top.material as any).diffuseTexture = texture2;
    planes.top.rotation.x = Math.PI / 2;
    planes.bottom.rotation.x = Math.PI / 2;
    planes.bottom.position.x = (i - mapW / 2) * unitsize;
    planes.bottom.position.y = -13;
    planes.bottom.position.z = (j - mapH / 2) * unitsize;
    planes.top.position.x = (i - mapW / 2) * unitsize;
    planes.top.position.y = unitsize - 14;
    planes.top.position.z = (j - mapH / 2) * unitsize;
    planes.top.checkCollisions = false;
    /*
    if (Math.random() > 0.8) {
      getLights(
        {
          dungeonLighting,
          position: new Vector3(
            planes.top.position.x,
            planes.top.position.y - 8,
            planes.top.position.z
          ),
          name: `SpotLight${i}-${Math.random()}`,
        },
        scene
      );
    }*/
  };

 export const randomFloor = () => randomItem(floorTextures);
 export const randomCeling = () => randomItem(celingTextures);
  
  export  const floorSky = (
    map: any,
    i: any,
    j: any,
    unitsize: any,
    scene: any,
    mapKeys: any,
    mapW: any,
    wallSize: any,
    mapH: any,
    store: any
  ) => {
    const planes = mapKeys[map[i][j]].exe(
      `type${mapKeys[map[i][j]].name}-${i}`,
      unitsize,
      scene
    );
    constructFloorAndSky(
      planes,
      scene,
      {
        top: store.fpSprites[randomCeling()].texture._texture._buffer,
        bottom: store.fpSprites[randomFloor()].texture._texture._buffer,
      },
      mapW,
      mapH,
      i,
      j,
      unitsize
    );
  };
  
  export  const basicWallorPlaneInit = (
    map: any,
    i: any,
    j: any,
    unitsize: any,
    scene: any,
    mapKeys: any,
    mapW: any,
    wallSize: any,
    mapH: any,
    store: any
  ) => {
    const { planes, sprite } = mapKeys[map[i][j]].exe(
      `type${mapKeys[map[i][j]].name}-${i}`,
      unitsize,
      scene
    );
    if (planes) {
      constructFloorAndSky(
        planes,
        scene,
        {
          top: store.fpSprites[randomCeling()].texture._texture._buffer,
          bottom: store.fpSprites[randomFloor()].texture._texture._buffer,
        },
        mapW,
        mapH,
        i,
        j,
        unitsize
      );
    }
  
    sprite.material = new StandardMaterial(`Mat${i}`, scene);
    const texture = new Texture(mapKeys[map[i][j]].texture, scene);
    texture.hasAlpha = true;
    (sprite.material as any).diffuseTexture = texture;
    sprite.position.x = (i - mapW / 2) * unitsize;
    sprite.position.y = wallSize / 2;
    sprite.position.z = (j - mapH / 2) * unitsize;
    sprite.checkCollisions = mapKeys[map[i][j]].checkCollisions;
  };
  
  export const basicWallorPlaneWithTextureInit = (
    map: any,
    i: any,
    j: any,
    unitsize: any,
    scene: any,
    mapKeys: any,
    mapW: any,
    wallSize: any,
    mapH: any,
    textureToRender: any
  ) => {
    const wallM = mapKeys[map[i][j]].exe(
      `type${mapKeys[map[i][j]].name}-${i}`,
      unitsize,
      scene
    );
    wallM.material = new StandardMaterial(`Mat${i}`, scene);
    const texture = textureToRender;
    texture.hasAlpha = true;
    texture.uScale = 1; // Adjust the texture tiling
    texture.vScale = 1;
    texture.uRotationCenter = 0.5; // Center the rotation around U-axis
    texture.vRotationCenter = 0.5; // Center the rotation around V-axis
    (wallM.material as any).diffuseTexture = texture;
    wallM.position.x = (i - mapW / 2) * unitsize;
    wallM.position.y = wallSize / 2;
    wallM.position.z = (j - mapH / 2) * unitsize;
    wallM.checkCollisions = mapKeys[map[i][j]].checkCollisions;
    return wallM
  };
  

  export const spriteWallorPlaneInit = (
    map: any,
    i: any,
    j: any,
    unitsize: any,
    scene: any,
    mapKeys: any,
    mapW: any,
    wallSize: any,
    mapH: any
  ) => {
    const spriteManagerWalls = new SpriteManager(
      "wallManager",
      mapKeys[map[i][j]].texture,
      30,
      72,
      scene
    );
    const wallM = new Sprite("sprite", spriteManagerWalls);
    wallM.cellIndex = 2;
    wallM.width = unitsize;
    wallM.height = unitsize;
    wallM.position.x = (i - mapW / 2) * unitsize;
    wallM.position.y = wallSize / 2 + 1.5;
    wallM.position.z = (j - mapH / 2) * unitsize;
  };


  export const createDoorLockText = () => {
    // Create a text block
    lockDoorGUI = BABYLONGUI.AdvancedDynamicTexture.CreateFullscreenUI("DOOR");
    textBlockDoor = new BABYLONGUI.TextBlock();
    textBlockDoor.text = "Awe Mate, the door is locked";
    textBlockDoor.color = "white";
    textBlockDoor.fontSize = 36;
    textBlockDoor.fontFamily = "Treasuremap";
    textBlockDoor.horizontalAlignment = BABYLONGUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    textBlockDoor.verticalAlignment = BABYLONGUI.Control.VERTICAL_ALIGNMENT_CENTER;
    lockDoorGUI.addControl(textBlockDoor);
    textBlockDoor.isVisible = false;
    
}

// Function to toggle the GUI visibility and start the fade animation
export const toggleAndFadeGUI = (scene) => {
  if (!textBlockDoor.isVisible) {
      // Show the GUI texture
      textBlockDoor.isVisible = true;

      // Create a fade animation for the text
      var animation = new Animation(
          "fadeAnimation",
          "alpha",
          60,
          Animation.ANIMATIONTYPE_FLOAT,
          Animation.ANIMATIONLOOPMODE_CONSTANT
      );

      // Define keyframes for the animation
      var keys = [];
      keys.push({ frame: 0, value: 1.0 }); // Fully visible at the start
      keys.push({ frame: 120, value: 0.0 }); // Completely transparent after 2 seconds

      // Set the animation keys
      animation.setKeys(keys);

      // Attach the animation to the text block
      textBlockDoor.animations = [animation];

      // Play the animation
      scene.beginAnimation(textBlockDoor, 0, 120, false, 1, () => {
          // Animation completed, hide the GUI texture
          textBlockDoor.isVisible = false;
      });
  }
}

export const setLockedDoor = (scene, door, store, enableCover) => {
  scene.onPointerObservable.add(function (evt) {
    if (evt.pickInfo.pickedMesh === door) {
      const storedData = localStorage.getItem("sc9App");
      let gameDataKey = false;
      let parsedData;
      if (storedData) {
        // Parse the JSON string to an object
        parsedData = JSON.parse(storedData);
        gameDataKey = parsedData.gameData.key;
      }
      if(gameDataKey){
        levelUp(store, scene, ++parsedData.gameData.level, enableCover);
      }else{
        toggleAndFadeGUI(scene)
      }
    }
  }, PointerEventTypes.POINTERPICK)
}