import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { randomItem } from "@utils";
import { SceneBabylon, BabylonBase } from "../Babylon";
import IOScene from "@IOEngine/game";
import NOAScene from "@NOAEngine"
import GreeSock from "../GS";
import CloudScene from "./CloudScene";
import loader from "@ui/svg/loader";
import { useStore } from "@store";

const { TextRotator, LogoRotator, NavBarMenu, NavModal } = GreeSock;

const LoaderImg = styled.div`
  height: 8.5vw;
  width: 8.5vw;
`;

const MainContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  position: relative;
`;

const HomeContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  bottom: 0, top: 0, left: 0, right: 0, 
  position: absolute, 
`;

const TextRotatorContainer = styled.div`
  display: flex;
  position: absolute;
  flex: 1;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  justify-content: center;
  align-items: flex-start;
  pointer-events: none;
`;

export const FRameContainer = styled.iframe`
  display: flex;
  width: 100vw;
  height: 100vh;
  user-select: none;
  &:focus {
    outline: none !important;
  }
`;

export const BabylonIOScene: any = (props: any) => <IOScene {...props} />;

export const BabylonScene: any = (props: any) => {
  props.setDisableLoader(true);
  return (
    <BabylonBase>
      <SceneBabylon disableZoom>
        <CloudScene />
      </SceneBabylon>
    </BabylonBase>
  );
};

export const NOAEgineScene: any = (props: any) => {
  props.setDisableLoader(true);
  return (
    <BabylonBase>
      <SceneBabylon disableZoom>
        <NOAScene />
      </SceneBabylon>
    </BabylonBase>
  );
};

export const AFrameScene: any = (props: any) => {
  props.setDisableLoader(true);
  return (
    <FRameContainer
      frameBorder="0"
      {...props}
      src="https://seacloud9.github.io/ds_aframe1/"
    />
  );
};

export interface DemoSceneProps {}

//const LoaderScene

const HomeContent = React.memo(({ setDisableLoader }: any) => {
  const arraNav = [<NavBarMenu />, <NavModal />];
  //const arraNav = [<NavBarMenu />];
  //const arraNav = [];
  
  const arrayOFScenes = [
    <BabylonScene setDisableLoader={setDisableLoader} />,
    <AFrameScene setDisableLoader={setDisableLoader} hidesLogo />,
  ];
  /*
  const arrayOFScenes = [
    <BabylonIOScene
      hidesLogo
      hidesTextRotator
      hidesNav
      setDisableLoader={setDisableLoader}
    />,
    <NOAEgineScene hidesLogo
    hidesTextRotator
    hidesNav
    setDisableLoader={setDisableLoader} />
    
  ];*/

  const SceneToRender: any = randomItem(arrayOFScenes);
  return (
    <>
      {
        // may need to update this rendering when we have multiple
        <TextRotatorContainer>
          {SceneToRender.props.hidesLogo ? null : <LogoRotator />}
          {SceneToRender.props.hidesTextRotator ? null : <TextRotator />}
        </TextRotatorContainer>
      }
      <HomeContainer>{SceneToRender}</HomeContainer>
      {SceneToRender.props.hidesNav ? null : randomItem(arraNav)}
    </>
  );
});

const DemoScene: any = (props: DemoSceneProps) => {
  const { disableLoader, setDisableLoader } = useStore();
  return (
    <MainContainer>
      <div className={`isDisabled-${!disableLoader}Loader`}>
        <span className="loader" />
      </div>
      <HomeContent setDisableLoader={setDisableLoader} />
      <div id="portalContainer" />
    </MainContainer>
  );
};

export default DemoScene;
