import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "styled-components";

const GradientBar = styled.div<any>`
  width: ${(props: any) => (props.rating / 5) * 100}%;
  height: 16px;
  border-radius: 16px;
  background: rgb(0, 212, 255);
  background: linear-gradient(
    90deg,
    rgba(0, 212, 255, 1) 3%,
    rgba(0, 124, 255, 1) 37%,
    rgba(9, 9, 121, 1) 82%,
    rgba(7, 1, 102, 1) 100%
  );
  border: solid 0.5px #007cff;
`;

const BoxDiv = styled(Box)`
  display: flex;
  width: 100%;
  padding: 4px;
  border: solid 0.5px #070166;
  border-radius: 16px;
`;

const SkillRating = ({ title = "React / React Native", rating = 1 }: any) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ textTransform: "lowercase" }}>
        <Typography variant="h6">{title}</Typography>
      </Box>
      <Box>
        <BoxDiv>
          <GradientBar rating={rating} />
        </BoxDiv>
      </Box>
    </Box>
  );
};

export default SkillRating;
