import React from "react";
import ReactRotatingText from "react-rotating-text";
import styled from "styled-components";

const TextContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  z-index: 1;
  position: absolute;
  flex: 0;
  display: flex;
  justify-content: center;
  opacity: 1;
  //scale(0)
`;

export interface LogoRotatorProps {
  txt: string[];
  color: string;
}

const TextRotator: any = (props: LogoRotatorProps) => {
  const Txt = props.txt
    ? props.txt
    : [
        "Hi, I'm Brendon",
        "I enjoy people",
        "I'm trustworthy, loyal, helpful, and courteous.",
        "I'm Creative",
        "I Love Design",
        "Happy to Build",
        "I'm constantly reducing complexity",
        "I'm a passionate engineer and artist",
      ];
  const color = props.color ? props.color : "#1e4877";
  return (
    <TextContainer>
      <ReactRotatingText className={"introText"} color={color} items={Txt} />
    </TextContainer>
  );
};

export default TextRotator;
