import { Box, Card, CardContent, CardMedia, Grid } from "@mui/material";
import StyledContainer from "@muiux/Container";
import ReactPlayer from "react-player";
import { styled } from "styled-components";
import ContactInfo from "@muiux//ContactInfo";

const IframeCard = styled(CardMedia)`
  iframe {
    box-shadow: none !important;
    padding: 0 !important;
  }
`;

const ContactModal = (props: any) => {
  return (
    <StyledContainer sx={{ width: "100%", marginTop: "16px" }}>
      <Grid container spacing={2}>
        {/* Column 1 */}
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              alignItems: "center",
              boxShadow: "none",
              background: "transparent",
            }}
          >
            <ContactInfo
              video={
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    marginTop: "16px",
                    justifyContent: "center",
                  }}
                >
                  <ReactPlayer
                    url={"https://youtu.be/3WyhHoXpcMM"}
                    width="auto"
                    height="auto"
                  />
                </Box>
              }
            />
          </Card>
        </Grid>
        {/* Column 2 */}
        <Grid item xs={12} md={8}>
          <Card>
            <IframeCard
              component="iframe"
              src="https://docs.google.com/forms/d/e/1FAIpQLSd3pzimQeRavwAwnE7OYI4mVzyScMZwhBJLCtJ0FqwBt_3Ghw/viewform?usp=sharing&embedded=true"
              sx={{ padding: 1, minHeight: 800 }}
            />
          </Card>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default ContactModal;
