import React, { useEffect } from "react";
import { Vector2, Mesh, Vector3 } from "@babylonjs/core";
import { useScene } from "react-babylonjs";
import "@babylon/shaders";

let customProceduralTexture = null;
let time = 0;

const onCustomProceduralTextureCreated = (cpt) => {
  customProceduralTexture = cpt;
};

export interface CloudSceneProps {
  position: Vector3;
  rotation: Vector3;
}

const CloudScene: any = (props: CloudSceneProps) => {
  const scene = useScene();
  useEffect(() => {
    //const engine = scene.getEngine();
    const observable = scene.onBeforeRenderObservable.add((scene) => {
      if (scene !== null && customProceduralTexture !== null) {
        time += scene.getEngine().getDeltaTime();
        // 'time' is a uniform on the shader
        customProceduralTexture.setFloat("time", time);
      }
    });
    return () => {
      scene.onBeforeRenderObservable.remove(observable);
    };
  });
  return (
    <plane
      name="seaCloud"
      width={40}
      height={40}
      position={props.position}
      rotation={props.rotation}
      billboardMode={Mesh.BILLBOARDMODE_ALL}
    >
      <standardMaterial name="shaderMat">
        <customProceduralTexture
          name="seaCloudsTexture"
          texturePath="clouds"
          size={1024}
          onCreated={onCustomProceduralTextureCreated}
          assignTo="diffuseTexture"
          setVector2={[
            "resolution",
            new Vector2(window.innerWidth, window.innerHeight),
          ]}
        />
      </standardMaterial>
    </plane>
  );
};

CloudScene.defaultProps = {
  position: new Vector3(0, 0, 0),
  rotation: new Vector3(0, 0, 0),
};

export default CloudScene;
