import React, { useEffect, useState } from "react";
import { Vector2, Mesh, Vector3 } from "@babylonjs/core";
import { useScene } from "react-babylonjs";
import { Engine } from 'noa-engine';
import { CreateBox } from '@babylonjs/core/Meshes/Builders/boxBuilder';
import { Inspector } from "@babylonjs/inspector";
import globals from "@utils/globals";
import exportVoxelData from "./utils";
const { isDebug } = globals;

let customProceduralTexture = null;
let time = 0;
const onCustomProceduralTextureCreated = (cpt) => {
  customProceduralTexture = cpt;
};

export interface NOASceneProps {
  position: Vector3;
  rotation: Vector3;
}

const NOAScene: any = (props: NOASceneProps) => {
  const scene = useScene();
  const [noaEngineScene, setNoaEngineScene] = useState(false)
  /*
  useEffect(() => {
    const observable = scene.onBeforeRenderObservable.add((scene) => {
      if (scene !== null && customProceduralTexture !== null) {
        time += scene.getEngine().getDeltaTime();
      }
    });

    return () => {
      scene.onBeforeRenderObservable.remove(observable);
    };
  });
  */

  useEffect(() => {
    if ((scene && scene?.getEngine() && scene?.getEngine().getRenderingCanvas()) && !noaEngineScene) {
    setNoaEngineScene(true)
    const opts = {
        debug: true,
        showFPS: true,
        chunkSize: 32,
        chunkAddDistance: 2.5,
        chunkRemoveDistance: 3.5,
      };
      const noa = new Engine(opts);

      const brownish = [0.45, 0.36, 0.22];
      const greenish = [0.1, 0.8, 0.2];
      noa.registry.registerMaterial('dirt', { color: brownish });
      noa.registry.registerMaterial('grass', { color: greenish });

      const dirtID = noa.registry.registerBlock(1, { material: 'dirt' });
      const grassID = noa.registry.registerBlock(2, { material: 'grass' });

      const getVoxelID = (x, y, z) => {
        if (y < -3) return dirtID;
        const height = 2 * Math.sin(x / 10) + 3 * Math.cos(z / 20);
        if (y < height) return grassID;
        return 0;
      }

      noa.world.on('worldDataNeeded', function (id, data, x, y, z) {
        for (let i = 0; i < data.shape[0]; i++) {
          for (let j = 0; j < data.shape[1]; j++) {
            for (let k = 0; k < data.shape[2]; k++) {
              const voxelID = getVoxelID(x + i, y + j, z + k);
              data.set(i, j, k, voxelID);
            }
          }
        }
        noa.world.setChunkData(id, data);
      });

      const player = noa.playerEntity;
      const dat = noa.entities.getPositionData(player);
      const w = dat.width;
      const h = dat.height;

      
      const mesh = CreateBox('player-mesh', {}, scene);
      mesh.scaling.x = w;
      mesh.scaling.z = w;
      mesh.scaling.y = h;

      mesh.material = noa.rendering.makeStandardMaterial('standard');
      /*
      noa.entities.addComponent(player, noa.entities.names.mesh, {
        mesh: mesh,
        offset: [0, h / 2, 0],
      });
      */

      noa.inputs.down.on('fire', function () {
        if (noa.targetedBlock) {
          const pos = noa.targetedBlock.position;
          noa.setBlock(0, pos[0], pos[1], pos[2]);
        }
      });

      noa.inputs.down.on('alt-fire', function () {
        if (noa.targetedBlock) {
          const pos = noa.targetedBlock.adjacent;
          noa.setBlock(grassID, pos[0], pos[1], pos[2]);
        }
      });

      noa.inputs.bind('alt-fire', 'KeyE');

      noa.on('tick', function (dt) {
        const scroll = noa.inputs.pointerState.scrolly;
        if (scroll !== 0) {
          noa.camera.zoomDistance += (scroll > 0) ? 1 : -1;
          if (noa.camera.zoomDistance < 0) noa.camera.zoomDistance = 0;
          if (noa.camera.zoomDistance > 10) noa.camera.zoomDistance = 10;
        }
      });

      // Add an input handler to export the voxel data
      noa.inputs.down.on('export-voxel-data',  () => {
        const fileName = prompt('Enter a name for the voxel data file:');
        if (fileName) {
        exportVoxelData(fileName, noa);
        }
      });

    noa.inputs.bind('export-voxel-data', 'KeyX')
    if (isDebug) {
        Inspector.Show(noa.rendering.getScene(), {
          embedMode: true,
        });
      }
     
    }
  }, [noaEngineScene, scene, scene?.getEngine()]);

  return null;
};

NOAScene.defaultProps = {
  position: new Vector3(0, 0, 0),
  rotation: new Vector3(0, 0, 0),
};

export default NOAScene;

