// Splash.tsx
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    filter: blur(10px);
    opacity: 0.4;
  }
  to {
    filter: blur(3px);
    opacity: 1;
  }
`;

const SplashContainer = styled(Box)<{ imgSrc: string }>`
  background-image: url("${(props: any) => props.imgSrc}");
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  filter: blur(3px);
  position: relative;
  animation: ${fadeIn} 20s ease-in-out;
  margin-bottom: 24px;
`;

const Splash = (props: any) => {
  return (
    <SplashContainer imgSrc={props.imgSrc}>{props.children}</SplashContainer>
  );
};

const SplashContainer2 = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url("/images/sc9bg2a.png");
  background-size: cover;
  background-position: center;
  filter: blur(3px);
  position: relative;
  animation: ${fadeIn} 20s ease-in-out;
  margin-bottom: 24px;
`;

export const Splash2 = (props: any) => {
  return <SplashContainer2>{props.children}</SplashContainer2>;
};

export default Splash;
