import React, { FC, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import gsap from "gsap";
import debounce from "debounce";
import PropTypes from "prop-types";
import { useScene } from "react-babylonjs";
import { AdvancedDynamicTexture, Rectangle, Control } from "@babylonjs/gui/2D";
import globals from "@utils/globals";
import { Vector3 } from "@babylonjs/core";
import { isMobile } from "@utils";
import { useStore } from "@store";
const { isDebug } = globals;
const MOBILE_MAP_DECRMENTER = 1;

interface Props {
  map: number[][];
  unitsize: number;
  tilesize: number;
  mapKeys: any;
}

const getMapSector = (v, unitsize, mapW, tilesize) => {
  let x = Math.floor((v.x + unitsize / 2) / unitsize + mapW / 2);
  let z = Math.floor((v.z + unitsize / 2) / unitsize + mapW / 2);
  // Convert to screen pixel coordinates
  let pixelX = Math.floor(x * tilesize);
  let pixelZ = Math.floor(z * tilesize);

  // Adjust for negative values
  if (v.x < 0) {
    pixelX = Math.abs(pixelX);
  }
  if (v.z < 0) {
    pixelZ = Math.abs(pixelZ);
  }

  return { x: pixelX, z: pixelZ };
};

const MapDisplay: FC<Props> = ({ map, unitsize, mapKeys, tilesize }) => {
  const scene = useScene();
  const { renderPixi } = useStore();
  const isDeviceMobile = isMobile();
  const textureRef = useRef<AdvancedDynamicTexture>();
  const backgroundRef = useRef<Rectangle>();
  const [tick, setTick] = useState(scene.activeCamera.position as Vector3);
  const previousMapPosition = useRef(scene.activeCamera.position.clone());

  const renderMap = () => {
    const camera = scene.activeCamera;
    const tileSize = isDeviceMobile
      ? tilesize * MOBILE_MAP_DECRMENTER
      : tilesize;
    const mapWidth = map.length;
    const width = map.length * tileSize;
    const height = map[0].length * tileSize;

    const background = new Rectangle();
    backgroundRef.current = background;
    background.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    background.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    background.width = `${width + 1}px`;
    background.height = `${height + 1}px`;
    background.background = "#fc03ec";
    background.left = "0px";
    background.top = "0px";
    // Create the map tiles
    map.forEach((row, y) => {
      row.forEach((cell, x) => {
        const tile = new Rectangle();
        tile.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        tile.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
        tile.width = `${tileSize}px`;
        tile.height = `${tileSize}px`;
        tile.background = mapKeys[cell].displayMapColor;
        tile.left = `${x * tileSize}px`;
        tile.top = `${y * tileSize}px`;
        background.addControl(tile);
      });
    });

    // Create the camera position marker
    if (camera) {
      const cameraMarker = new Rectangle();
      cameraMarker.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
      cameraMarker.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
      cameraMarker.width = `${tileSize}px`;
      cameraMarker.height = `${tileSize}px`;
      cameraMarker.background = "#0f0";

      //cameraMarker.left = `${(camera.position.x) + tileSize}px`;
      if (isDebug) {
        (window as any).camera = camera;
        (window as any).cameraMarker = cameraMarker;
      }

      const _movementThreshold = new Vector3(unitsize, 0, unitsize);
      const deltaVector = camera.position.subtract(previousMapPosition.current);
      /*
      if(renderPixi){
        if(textureRef.current){
          textureRef.current.clear();
          setTick(camera.position);
          return null
        }
      }
      */

      if (
        Math.abs(deltaVector.x) >= _movementThreshold.x ||
        Math.abs(deltaVector.z) >= _movementThreshold.z
      ) {
        setTick(camera.position);
        previousMapPosition.current = camera.position.clone();
        // Trigger render here or perform any other necessary actions
      } else {
        return null;
      }

      const pos = getMapSector(
        { x: camera.position.x, z: camera.position.z },
        unitsize,
        mapWidth,
        tilesize
      );
      cameraMarker.left = `${pos.z}px`;
      cameraMarker.top = `${pos.x}px`;
      background.addControl(cameraMarker);
    }

    if (!textureRef.current) {
      textureRef.current = AdvancedDynamicTexture.CreateFullscreenUI("UI");
      textureRef.current.addControl(background);
    } else {
      textureRef.current.addControl(background);
    }
  };

  let rafId;
  let previousTime = 0;
  const interval = 2500;

  useEffect(() => {
    const handleAnimationFrame = (currentTime) => {
      if (currentTime - previousTime >= interval) {
        // Function logic
        previousTime = currentTime;
        renderMap();
        if (rafId) {
          cancelAnimationFrame(rafId);
        }
      }
      rafId = requestAnimationFrame(handleAnimationFrame);
    };

    rafId = rafId ? null : requestAnimationFrame(handleAnimationFrame);
    return () => {
      cancelAnimationFrame(rafId); // Cleanup on component unmount
    };
  }, []);

  /*
  useEffect(() => {
      //cancelAnimationFrame(rafId)
      backgroundRef.current = null;
      textureRef.current = AdvancedDynamicTexture.CreateFullscreenUI("UI");
      textureRef.current.clear()
      renderMap();
      const camera = scene.activeCamera;
      setTick(camera.position);
  }, [map])
  */

  return null;
};

MapDisplay.propTypes = {
  map: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.number.isRequired).isRequired
  ).isRequired,
  unitsize: PropTypes.number,
  tilesize: PropTypes.number,
};

export default MapDisplay;
