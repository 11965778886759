// CardContentImage.js
import React from "react";
import CardContent from "@mui/material/CardContent";
import styled from "styled-components";

const CardContentImageBG = styled(CardContent).withConfig({
  shouldForwardProp: (prop) => prop !== "imageSrc",
})<{ imageSrc: string }>`
  background-image: url(${(props) => props.imageSrc});
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  padding: 0;
`;

const CardContentImage = ({ imageSrc, children }: any) => {
  return (
    <CardContentImageBG imageSrc={imageSrc} style={{ paddingBottom: 0 }}>
      {children}
    </CardContentImageBG>
  );
};

export default CardContentImage;
