// CardStyles.ts
import styled from "styled-components";
import { Card as MuiCard, CardContent as MuiCardContent } from "@mui/material";

export const CardContainer = styled(MuiCard)`
  width: 100%;
  margin-bottom: 16px;
  border-radius: 24px;
`;

export const CardContent = styled(MuiCardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Avatar = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
`;

export const CardTitle = styled.h2`
  font-size: 1.5rem;
  margin: 8px 0;
`;

export const Subtitle = styled.div`
  padding: 8px;
`;

export const ToggleSwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentText = styled.div`
  font-size: 1rem;
`;

export const PhoneNumber = styled.p`
  font-size: 0.875rem;
`;

export const GlobalSwitchStyle = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
