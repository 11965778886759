import React, { useState } from "react";
import {
  CardContainer,
  CardContent,
  Avatar,
  Subtitle,
  ToggleSwitchWrapper,
  ContentText,
  PhoneNumber,
} from "./styles";
import CardContentImage from "./CardContentImage";
import ToggleButton from "../ToggleButton";
import IosSwitchMaterialUi from "ios-switch-material-ui";
import { Box, Typography } from "@mui/material";
import Modal from "../Modal";
import ContactModal from "@screens/contact-modal";
//import { CardProps } from '@mui/material';

interface CardProps {
  title: string;
  avatarImageSrc?: string;
  name?: string;
  subtitle?: string;
  toggleSwitch?: boolean;
  content?: JSX.Element;
  phoneNumber?: string;
  backgroundImage?: any;
  titleColor?: string;
  titleBackground?: string;
  cardBackground?: string;
  initialToggleState?: boolean;
  toggleOptions?: any;
}

const SwitchContainer: any = () => {
  const [toogleIsOpen, setToogleIsOpen] = useState(false);
  const toggleOptions: any = { toogleIsOpen, setToogleIsOpen };
  return (
    <ToggleSwitchWrapper>
      <div>
        <IosSwitchMaterialUi
          knobSize={50}
          aspectRatio={4}
          colorKnobOnLeft="#d9e6f2"
          colorKnobOnRight="#97bbdc"
          knobOnLeft={toggleOptions.toogleIsOpen}
          colorSwitch="#25649c78"
          onChange={() =>
            toggleOptions.setToogleIsOpen(!toggleOptions.toogleIsOpen)
          }
        />
        <Modal
          fullScreen={true}
          open={toggleOptions.toogleIsOpen}
          handleClose={() =>
            toggleOptions.setToogleIsOpen(!toggleOptions.toogleIsOpen)
          }
          title={"Let's Talk"}
          style={{ backgroundColor: "#f4f4f1" }}
        >
          <CardContent
            sx={{
              display: "flex",
              padding: 0,
              justifyContent: "center",
              paddingTop: "16px",
              backgroundColor: "#f4f4f1",
            }}
            style={{ paddingBottom: 0 }}
          >
            <ContactModal />
          </CardContent>
        </Modal>
      </div>
    </ToggleSwitchWrapper>
  );
};

const Card: React.FC<CardProps> = ({
  title,
  avatarImageSrc,
  name,
  subtitle,
  toggleSwitch,
  content,
  phoneNumber,
  backgroundImage,
  titleColor = "black",
  titleBackground = "transparent",
  cardBackground = "white",
  initialToggleState = false,
  toggleOptions,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  let cardStyles: any = {
    padding: 0,
  };
  if (cardBackground) {
    cardStyles.backgroundColor = cardBackground;
  }

  const Content = () => (
    <ToggleButton
      titleBackground={titleBackground}
      buttonText={title}
      color={titleColor}
      initialState={initialToggleState}
    >
      <Box
        style={{
          padding: 16,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {avatarImageSrc && (
          <Box>
            <Avatar src={avatarImageSrc} alt="Avatar" />
          </Box>
        )}
        {name && (
          <Subtitle>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {name}
            </Typography>
          </Subtitle>
        )}
        {toggleSwitch && <SwitchContainer />}
        {content && <ContentText>{content}</ContentText>}
        {phoneNumber && <PhoneNumber>{phoneNumber}</PhoneNumber>}
      </Box>
    </ToggleButton>
  );

  return (
    <>
      <CardContainer>
        {backgroundImage ? (
          <CardContentImage imageSrc={backgroundImage}>
            <Content />
          </CardContentImage>
        ) : (
          <CardContent sx={cardStyles} style={{ padding: 0 }}>
            <Content />
          </CardContent>
        )}
      </CardContainer>
    </>
  );
};

export default Card;
