import React, { useEffect, useRef } from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";
import { Tween, Timeline } from "react-gsap";
const Content = styled.div``;
const Logo = styled.img``;

const AnimatedLogo: React.FC<any> = () => {
  return (
    <Content className="logoContainer">
      <Logo src={"/images/logo.svg"} className="logoStyle" />
    </Content>
  );
};

export default AnimatedLogo;
