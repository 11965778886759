// Splash.tsx
import React, { useEffect } from "react";
import styled from "styled-components";
import { Tween, Timeline } from "react-gsap";
import AnimatedText from "./AnimatedText";

export const MainContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  margin-bottom: 24px;
`;

export const TextContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1;
  overflow: hidden;
  font-family: "Inter", sans-serif;
`;

const Triangle = styled.div<{ color: string }>`
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 100vh 100vw 0 0;
  border-color: ${(props) => props.color} transparent transparent transparent;
  //transform: rotate(180deg);
  animation: fadeIn 2s ease-in-out;
`;

const TriangleAnimation = styled.div`
  animation: fadeIn 2s ease-in-out;
  opacity: 0;
`;

const ColorSplash = () => {
  return (
    <MainContainer>
      <Timeline target={<Triangle color="#02029163" />} repeat={0}>
        <Tween
          from={{ left: "-100%", top: "0%" }}
          to={{ left: "0%", top: "0%" }}
        />
      </Timeline>
      <Timeline
        target={
          <Triangle color="#91022e63" style={{ transform: "rotate(180deg)" }} />
        }
        repeat={0}
      >
        <Tween
          from={{ left: "100%", top: "0%" }}
          to={{ left: "0%", top: "0%" }}
        />
      </Timeline>
    </MainContainer>
  );
};

export default ColorSplash;
