import {
  createAssets,
  createSpriteFromCanvas,
  importGLBAsync,
  importGLBModelAsync,
} from "@IOEngine/utilities";
import * as BABYLON from "@babylonjs/core";
// maxCount of all sprites
export const spriteSheetLength = 112;
// width of sprite sheet items
export const HORIZONTALWIDTH = 28;
const gameAPI: string | undefined = process.env.REACT_APP_GAME_API_ENDPOINT;
const isDebug: boolean | undefined =
  process.env.REACT_APP_IS_DEBUG === "true" ? true : false;
const readScenes = "readScenes";

export const cameraLevelPosition: any = {
  0: [-170, -0.985, -130],
  1: [135, -3, 147],
};

export const modelsToLoad = [
  {
    name: "skullCrusher",
    model: "redskull.glb",
    scale: 2.5,
    position: [-160, 1, 1],
    rotation: [0, Math.PI / 2, 0, 0],
  },
];

export const setUpAllSprites = (store: any, scene: any): any => {
  createAssets(`wallAssets`, `/images/fps/sprite_fps.png`, scene, (asset) => {
    let spritesArray = [];
    for (let i = 0; i < spriteSheetLength; i++) {
      if (i === 0 || i % HORIZONTALWIDTH === 0) {
        let yOffsetPX = i !== 0 ? (i / HORIZONTALWIDTH) * 72 : 0;
        const getGameTexture = (incTex = 0, spriteSize = 72) => ({
          name: `wall${i + incTex}`,
          texture: new BABYLON.Texture(
            `data:wall${i + incTex}`,
            scene,
            false,
            true,
            BABYLON.Texture.BILINEAR_SAMPLINGMODE,
            null,
            null,
            createSpriteFromCanvas(
              `wall${i + incTex}`,
              asset,
              incTex * spriteSize,
              yOffsetPX,
              spriteSize,
              spriteSize
            ),
            true
          ),
        });
        // uses width of the sprite to build out canvas sprites
        for (let xinc = 0; xinc <= HORIZONTALWIDTH; xinc++) {
          spritesArray.push(getGameTexture(xinc));
        }
      }
    }

    store.setActiveSprites(spritesArray);
  });
};

const getModel = async (store, scene, item: any) => {
  let _model: any;
  try {
    _model = await importGLBAsync(scene, {
      name: item.name,
      path: `/models/`,
      filename: item.model,
      scaling: item.scale,
      rotation: new BABYLON.Quaternion(...item.rotation),
      position: new BABYLON.Vector3(...item.position),
    });
    store.setActiveModels(_model);
  } catch (e) {
    console.log("model import error: ", e);
  }
  return _model;
};

const getAllGlbModels = (store: any, scene: any) => {
  modelsToLoad.map((item) => getModel(store, scene, item));
};

const sortAndSetGames = (data: any, store: any): any => {
  // sort timestamps
  let games = data.Items.map((item: any) => {
    const { startTime, prize, description, thumbnail, status } = item;
    return { startTime, prize, description, thumbnail, status };
  });
  games = data.Items.sort(
    (itemA: any, itemB: any) => itemB.startTime - itemA.startTime
  );
  // latest active game
  let currentTime = Date.now() / 1000;
  //<GameProgressImage />
  let activeGames = games.filter((item: any) => {
    if (item.startTime < currentTime) item.currentStatus = "inprogress";
    return item.startTime < currentTime;
  });
  let inactiveGames = games.filter((item: any) => {
    if (item.startTime >= currentTime) item.currentStatus = "locked";
    return item.startTime >= currentTime;
  });
  store.setInactiveGames(inactiveGames);
  store.setActiveGame(activeGames[0]);
  store.setActiveGames(activeGames);
  // cameras is set to current active game
  store.setCameraViews(activeGames[0].cameras);
  return data;
};
export default {
  getAllGlbModels,
  spriteSheetLength,
  isDebug,
  endpoints: {
    readScenes: gameAPI + readScenes,
  },
  sortAndSetGames,
  setUpAllSprites,
};
