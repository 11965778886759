import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import styled from "styled-components";
import { Tween, Timeline } from "react-gsap";
import { Typography } from "@mui/material";

interface Props {
  buttonText: string;
  children: React.ReactNode;
  color?: string;
  initialState?: boolean;
}

export const AnimatedButton = styled(Button)<{
  isOpen: boolean;
  colorType?: string;
}>`
  background: transparent;
  padding: 0px;
  display: flex;
  align-items: flex-start;
  transition: all 0.3s ease;

  ${(props) =>
    props.isOpen &&
    `
    transform: rotate(180deg);
  `}

  ${(props) =>
    props.colorType &&
    `
    color: ${props.colorType};
  `}
`;

export const ButtonText = styled.div<{ color?: string }>`
  width: 100%;
  text-align: center;
  position: absolute;
  font-weight: bold;
  z-index: 1;
  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `}
`;

export const ButtonOutsideContainer = styled.div<{ titleBackground?: string }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  ${(props) =>
    props.titleBackground &&
    `
    background-color: ${props.titleBackground};
  `}
`;

export const ButtonContainer = styled.div<{ titleBackground?: string }>`
  display: flex;
  flex: 1;
  position: relative;
  cursor: pointer;
  ${(props) =>
    props.titleBackground !== "trasparent" &&
    `
    padding-bottom: 16px;
    padding-top: 16px;
  `}
`;

export const ContentContainer = styled.div`
  display: flex;
  overflow: hidden; /* Hide content when not open */
  flex-direction: column;
`;

const ToggleButton: React.FC<any> = ({
  buttonText,
  children,
  color = "black",
  titleBackground = "transparent",
  initialState = false,
}: any) => {
  const [isOpen, setIsOpen] = useState(initialState);

  const toggleButton = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ButtonOutsideContainer titleBackground={titleBackground}>
      <ButtonContainer onClick={toggleButton} titleBackground={titleBackground}>
        <AnimatedButton isOpen={isOpen} colorType={color}>
          <KeyboardArrowDownIcon />
        </AnimatedButton>
        <ButtonText color={color}>
          <Typography
            variant="h6"
            sx={{ textTransform: "lowercase", fontWeight: "bold" }}
          >
            {buttonText}
          </Typography>
        </ButtonText>
      </ButtonContainer>
      <Tween
        from={{
          y: isOpen ? 0 : "100%",
          opacity: isOpen ? 0 : 1,
          height: isOpen ? 0 : "auto",
        }}
        to={{
          y: isOpen ? "100%" : 0,
          opacity: isOpen ? 0 : 1,
          height: isOpen ? 0 : "auto",
        }}
        duration={0.2}
      >
        <ContentContainer>{children}</ContentContainer>
      </Tween>
    </ButtonOutsideContainer>
  );
};

export default ToggleButton;
