import * as BABYLON from "@babylonjs/core";
import { spriteSheetLength } from "@utils/globals";
import { basicWallorPlaneInit, basicWallorPlaneWithTextureInit, constructFloorAndSky, createDoorLockText, doorTextures, floorSky, keyTextures, randomCeling, randomFloor, setLockedDoor, spriteWallorPlaneInit } from "./utils";
import { getItemFromArray, importGLBModelAsync, interactableVideoPlane, levelKey, moveCloserToTarget } from "../utilities";

const COUNT_OFFSET = 6

// 9 door 
export const keys = {
    0: {
      texture: "/images/fps/sprite_fps.png",
      displayMapColor: "#fff",
      exe: (name: any, unitsize: any, scene: any) => {
        return {
          top: BABYLON.Mesh.CreatePlane(
            name,
            unitsize,
            scene,
            true,
            BABYLON.Mesh.DOUBLESIDE
          ),

          bottom: BABYLON.Mesh.CreatePlane(
            name,
            unitsize,
            scene,
            true,
            BABYLON.Mesh.DOUBLESIDE
          ),
        };
      },
      init: (
        map,
        i,
        j,
        unitsize,
        scene,
        mapKeys,
        mapW,
        wallSize,
        mapH,
        store,
        enableCover
      ) => {
        floorSky(
          map,
          i,
          j,
          unitsize,
          scene,
          mapKeys,
          mapW,
          wallSize,
          mapH,
          store
        );
      },
      name: "blankSpace",
      checkCollisions: false,
    },
    1: {
      texture: "/images/io/textures/crate.png",
      displayMapColor: "#000",
      exe: (name: any, unitsize: any, scene: any) => ({
        sprite: BABYLON.Mesh.CreateBox(name, unitsize, scene),
      }),
      init: (
        map,
        i,
        j,
        unitsize,
        scene,
        mapKeys,
        mapW,
        wallSize,
        mapH,
        store,
        enableCover
      ) =>
        basicWallorPlaneInit(
          map,
          i,
          j,
          unitsize,
          scene,
          mapKeys,
          mapW,
          wallSize,
          mapH,
          store
        ),
      name: "crate",
      checkCollisions: true,
    },
    2: {
      // double sided texture
      texture: "/images/io/textures/doublesided.jpg",
      displayMapColor: "#F00",
      exe: (name: any, unitsize: any, scene: any) => {
        const f = new BABYLON.Vector4(0, 0, 0.5, 1); // front image = half the whole image along the width
        const b = new BABYLON.Vector4(0.5, 0, 1, 1); // back image = second half along the width
        const planeOpts = {
          width: unitsize,
          height: unitsize,
          frontUVs: f,
          backUVs: b,
          sideOrientation: BABYLON.Mesh.DOUBLESIDE,
        };
        return {
          sprite: BABYLON.MeshBuilder.CreatePlane(name, planeOpts),
          planes: {
            top: BABYLON.Mesh.CreatePlane(
              name,
              unitsize,
              scene,
              true,
              BABYLON.Mesh.DOUBLESIDE
            ),

            bottom: BABYLON.Mesh.CreatePlane(
              name,
              unitsize,
              scene,
              true,
              BABYLON.Mesh.DOUBLESIDE
            ),
          },
        };
      },
      init: (
        map,
        i,
        j,
        unitsize,
        scene,
        mapKeys,
        mapW,
        wallSize,
        mapH,
        store,
        enableCover
      ) =>
        basicWallorPlaneInit(
          map,
          i,
          j,
          unitsize,
          scene,
          mapKeys,
          mapW,
          wallSize,
          mapH,
          store
        ),
      name: "DoubleSidedFrame",
    },
    3: {
      // sprite 
      texture: "/images/io/textures/plant.png",
      displayMapColor: "#F00",
      exe: (name: any, unitsize: any, scene: any) => {
        return {
          sprite: BABYLON.Mesh.CreatePlane(
            name,
            unitsize,
            scene,
            true,
            BABYLON.Mesh.DOUBLESIDE
          ),
          planes: {
            top: BABYLON.Mesh.CreatePlane(
              name,
              unitsize,
              scene,
              true,
              BABYLON.Mesh.DOUBLESIDE
            ),

            bottom: BABYLON.Mesh.CreatePlane(
              name,
              unitsize,
              scene,
              true,
              BABYLON.Mesh.DOUBLESIDE
            ),
          },
        };
      },
      init: (
        map,
        i,
        j,
        unitsize,
        scene,
        mapKeys,
        mapW,
        wallSize,
        mapH,
        store,
        enableCover
      ) =>
        basicWallorPlaneInit(
          map,
          i,
          j,
          unitsize,
          scene,
          mapKeys,
          mapW,
          wallSize,
          mapH,
          store
        ),
      name: "planeTexture",
      checkCollisions: true,
    },
    4: {
      // video display
      displayMapColor: "#ffc0c000",
      init: (
        map,
        i,
        j,
        unitsize,
        scene,
        mapKeys,
        mapW,
        wallSize,
        mapH,
        store,
        enableCover
      ) => {
        constructFloorAndSky(
          {
            top: BABYLON.Mesh.CreatePlane(
              `constructFloorAndSkyVid1`,
              unitsize,
              scene,
              true,
              BABYLON.Mesh.DOUBLESIDE
            ),
            bottom: BABYLON.Mesh.CreatePlane(
              `constructFloorAndSkyVid2`,
              unitsize,
              scene,
              true,
              BABYLON.Mesh.DOUBLESIDE
            ),
          },
          scene,
          {
            top: store.fpSprites[randomCeling()].texture._texture._buffer,
            bottom: store.fpSprites[randomFloor()].texture._texture._buffer,
          },
          mapW,
          mapH,
          i,
          j,
          unitsize
        );
        const planeVid = interactableVideoPlane(
          scene,
          "/images/video/thumb/candy_crush_royalty_thumb.png",
          "/images/video/candy_crush_royalty.m4v",
          40,
          20
        );
        planeVid.position.x = (i - mapW / 2) * unitsize;
        planeVid.position.y = wallSize / 2 + 1.5;
        planeVid.position.z = (j - mapH / 2) * unitsize;
      },
      name: "vidTexture",
      checkCollisions: true,
    },
    5: {
      texture: "/images/fps/sprite_fps.png",
      displayMapColor: "#000",
      init: (map, i, j, unitsize, scene, mapKeys, mapW, wallSize, mapH) =>
        spriteWallorPlaneInit(
          map,
          i,
          j,
          unitsize,
          scene,
          mapKeys,
          mapW,
          wallSize,
          mapH
        ),
      name: "planeTexture",
      checkCollisions: true,
    },
    ...Object.fromEntries(
      Array.from({ length: spriteSheetLength }, (_, ix) => [
        ix + COUNT_OFFSET,
        {
          texture: "/images/fps/sprite_fps.png?",
          displayMapColor: "#000",
          exe: (name: any, unitsize: any, scene: any) => {
            const cube = BABYLON.MeshBuilder.CreateBox(
              "cube",
              {
                size: unitsize,
                wrap: true,
              },
              scene
            );
            return cube;
          },
          init: (
            map,
            i,
            j,
            unitsize,
            scene,
            mapKeys,
            mapW,
            wallSize,
            mapH,
            store,
            enableCover
          ) => {
            const item = basicWallorPlaneWithTextureInit(
              map,
              i,
              j,
              unitsize,
              scene,
              mapKeys,
              mapW,
              wallSize,
              mapH,
              store.fpSprites[ix].texture
            );
            // used to override sprite sheet data
            switch(ix + COUNT_OFFSET){
              // door 
              case parseInt(doorTextures[0] as any): 
                setLockedDoor(scene, item, store, enableCover)
                return
              default:
                 return   
            }

          },
          checkCollisions: true,
        },
      ])
    ),
    113: {
      displayMapColor: "#ffc0c000",
      init: (
        map,
        i,
        j,
        unitsize,
        scene,
        mapKeys,
        mapW,
        wallSize,
        mapH,
        store,
        enableCover
      ) => {
        // key
        constructFloorAndSky(
          {
            top: BABYLON.Mesh.CreatePlane(
              `constructFloorAndSkyVid1`,
              unitsize,
              scene,
              true,
              BABYLON.Mesh.DOUBLESIDE
            ),
            bottom: BABYLON.Mesh.CreatePlane(
              `constructFloorAndSkyVid2`,
              unitsize,
              scene,
              true,
              BABYLON.Mesh.DOUBLESIDE
            ),
          },
          scene,
          {
            top: store.fpSprites[randomCeling()].texture._texture._buffer,
            bottom: store.fpSprites[randomFloor()].texture._texture._buffer,
          },
          mapW,
          mapH,
          i,
          j,
          unitsize
        );
        levelKey(
          scene,
          12,
          12,
          store.fpSprites[keyTextures[0]].texture,
          mapW,
          mapH,
          i,
          j,
          unitsize,
          store.fpSprites[14].texture,
          store
        );
      },
      name: "keyTexture",
      checkCollisions: true,
    },
    114: {
      // enemy
      displayMapColor: "#f5050500",
      init: (
        map,
        i,
        j,
        unitsize,
        scene,
        mapKeys,
        mapW,
        wallSize,
        mapH,
        store,
        enableCover
      ) => {
        constructFloorAndSky(
          {
            top: BABYLON.Mesh.CreatePlane(
              `constructFloorAndSkyVid1`,
              unitsize,
              scene,
              true,
              BABYLON.Mesh.DOUBLESIDE
            ),
            bottom: BABYLON.Mesh.CreatePlane(
              `constructFloorAndSkyVid2`,
              unitsize,
              scene,
              true,
              BABYLON.Mesh.DOUBLESIDE
            ),
          },
          scene,
          {
            top: store.fpSprites[randomCeling()].texture._texture._buffer,
            bottom: store.fpSprites[randomFloor()].texture._texture._buffer,
          },
          mapW,
          mapH,
          i,
          j,
          unitsize
        );
        let enemeyCharacter:any = store.fpModels[0]
        const speed = 20.0; // Movement speed (units per second)
        let lastTimestamp = 0;
        enemeyCharacter.meshes[0].billboardMode = BABYLON.AbstractMesh.BILLBOARDMODE_ALL; 
        scene.onBeforeRenderObservable.add(() => {
            // Calculate the time elapsed since the last frame
            const now = performance.now();
            const deltaTime = (now - lastTimestamp) / 1000; // Convert milliseconds to seconds
            if(!(window as any).renderPixi &&  scene.activeCamera){
              // Move the hunted mesh closer to the hun ter mesh
              moveCloserToTarget(enemeyCharacter.meshes[0], scene.activeCamera.position, speed, deltaTime);
              const lookAt = BABYLON.Matrix.LookAtLH(
                enemeyCharacter.meshes[0].position,
                scene.activeCamera.position,
                BABYLON.Vector3.Up()
              ).invert();
            }
            lastTimestamp = now;
        });

      },
      name: "enemyCharacter",
      checkCollisions: true,
    },
  };

  