import TextScroller from "@muiux/TextScroller";
import Splash, { Splash2 } from "../Splash";
import AnimatedLogo from "../Splash/AnimatedLogo";
import AnimatedText from "../Splash/AnimatedText";
import ColorSplash, {
  MainContainer,
  TextContainer,
} from "../Splash/ColorSplash";
import { styled } from "styled-components";
import { Link } from "react-router-dom";
import { FRameContainer } from "@ui/DemoScene";
import { randomItem } from "@utils";
import { Box } from "@mui/material";
const isIOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const platform = window.navigator.platform.toLowerCase();
  
  const isIOS = /iphone|ipad|ipod/.test(userAgent) && !(window as any).MSStream;
  const isIPadOS = /macintosh/.test(userAgent) && navigator.maxTouchPoints > 1;
  
  return isIOS || isIPadOS;
}


const TickerContainer = styled.div`
  position: absolute;
  display: flex;
  left: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 2;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
`;

const TextSpanEM = styled.span`
  font-family: Rapscallion;
`;

const SCLink: any = styled(Link)<any>`
  text-decoration: none;
  font-size: inherit;
  color: inherit;
`;

const SCSup = styled.sup`
  vertical-align: super;
  font-size: smaller;
`;

const FrameContent: any = ({ iframe, imgSrc }: any) => {
  //"/scenes/cables_shape_shifter_reflection/index.html"
  return (
    <>
      <TextContainer>
        <AnimatedText
          text={
            <>
              {`SEACLOUD`}
              <SCSup>9</SCSup>
            </>
          }
          text2="studio"
        />
      </TextContainer>
      <TickerContainer>
        <TextScroller>
          <p>
            /fullstack/end-to-end/web developer/frontend developer/mobile
            developer/
            <SCLink
              onClick={() => ((window as any).location = "/experimental")}
            >
              <TextSpanEM>**</TextSpanEM>creative engineer
              <TextSpanEM>**</TextSpanEM>
            </SCLink>
            /freelancer/teacher
          </p>
        </TextScroller>
      </TickerContainer>
      <Splash imgSrc={imgSrc}>
        <Box style={{ position: "absolute", zIndex: 2, opacity: 0.6 }}>
          <FRameContainer frameBorder="0" src={iframe} />
        </Box>
        <ColorSplash />
      </Splash>
    </>
  );
};

const Content1 = () => {
  return (
    <>
      <TextContainer>
        <AnimatedText
          text={
            <>
              {`SEACLOUD`}
              <SCSup>9</SCSup>
            </>
          }
          text2="studio"
        />
      </TextContainer>
      <TickerContainer>
        <TextScroller>
          <p>
            /fullstack/end-to-end/web developer/frontend developer/mobile
            developer/
            <SCLink
              onClick={() => ((window as any).location = "/experimental")}
            >
              <TextSpanEM>**</TextSpanEM>creative engineer
              <TextSpanEM>**</TextSpanEM>
            </SCLink>
            /freelancer/teacher
          </p>
        </TextScroller>
      </TickerContainer>
      <Splash imgSrc={"/images/sc9bg1.jpeg"}>
        <ColorSplash />
      </Splash>
    </>
  );
};

const arrayOFScenes = () =>  {
  let scenesArrayDefault = [
    <FrameContent
      iframe={"/scenes/cables_speedoflight-demoscene/index.html"}
      imgSrc={"/images/sc9bg1.jpeg"}
    />,
    <FrameContent
      iframe={"/scenes/cables_DemoScene011/index.html"}
      imgSrc={"/images/sc9bg1.jpeg"}
    />,
    <FrameContent
      iframe={"/scenes/cables_shapeshifter_clouds/index.html"}
      imgSrc={"/images/sc9bg1.jpeg"}
    />,
    <FrameContent
      iframe={"/scenes/voxel_vader_babylonjs/index.html"}
      imgSrc={"/images/sc9bg1.jpeg"}
    />,  
    <FrameContent
      iframe={"/scenes/cables_shape_shifter_reflection/index.html"}
      imgSrc={"/scenes/cables_shape_shifter_reflection/assets/sc9bg2a.png"}
    />,
    <Content1 />,
    <FrameContent
      iframe={"/scenes/cables_OceanWaves/index.html"}
      imgSrc={"/images/sc9bg1.jpeg"}
    />,
    <FrameContent
      iframe={"/scenes/cables_abstract/index.html"}
      imgSrc={
        "/scenes/cables_abstract/assets/seacloud9_abstract_huge_enormous_sapphire_reflecting_cannon_bea_e05a0ed7-ffcf-415a-8b32-29c7f52fab34.png"
      }
    />,
  ]
  // videos for non-ios or macOS
  if(!isIOS()){
    scenesArrayDefault.push(<FrameContent
      iframe={"/scenes/dusk_by_a_lake/index.html"}
      imgSrc={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwAB/ep9kAAAAASUVORK5CYII="}
    />,)
  }

  return (scenesArrayDefault)};

const Hero = () => <MainContainer>{randomItem(arrayOFScenes())}</MainContainer>;

export default Hero;
