import React from "react";
import styled, { keyframes } from "styled-components";

interface TextTickerScrollerProps {
  children: any;
}

const scroll = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-300%);
  }
`;

const scroll2 = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-250%);
  }
`;

const TickerContainer = styled.div`
  width: 150%;
  overflow: hidden;
  white-space: nowrap;
`;

const TickerText = styled.div`
  animation: ${scroll} 40s linear infinite;
  @media (min-width: 768px) {
    /* Change 768px to your desired desktop breakpoint */
    animation: ${scroll2} 60s linear infinite;
  }
`;

const TextTickerScroller: React.FC<TextTickerScrollerProps> = ({
  children,
}) => {
  return (
    <TickerContainer>
      <TickerText>{children}</TickerText>
    </TickerContainer>
  );
};

export default TextTickerScroller;
