import { StateCreator } from "zustand";
import { Camera, Games, Game, FPSprites, FPSprite } from "./MockStore";
import { localStorageKey } from "./index";
import serializeJavascript from "serialize-javascript";

export const InitialState = {
  stage: null,
  currentIndex: 0,
  renderInc: 1,
  enabledRoot: false,
  disableLoader: false,
  renderPixi: true,
  displayCover: false,
  activeGame: null,
  fpSprite: null,
  cameraViews: [],
  activeGames: [],
  fpSprites: [],
  fpModels: [],
  inactiveGames: [],
  modalIsOpen: false,
  gameData: {
    items: [],
    key: false,
    level: 0,
  },
};

export const createAppSlice: StateCreator<
  AppSlice,
  [["zustand/subscribeWithSelector", never]],
  []
> = (set, get) => {
  const storedState = JSON.parse(localStorage.getItem(localStorageKey) as any);

  const updateLocalStorage = (state: any) => {
    const stateWithoutFunctions = removeFunctions(state);
    const serializedState = serializeJavascript(stateWithoutFunctions);
    localStorage.setItem(
      localStorageKey,
      JSON.stringify(stateWithoutFunctions)
    );
  };

  const removeFunctions = (obj: any): any => {
    const newObj: any = {};
    for (let key in obj) {
      if (
        typeof obj[key] !== "function" &&
        key !== "fpModels" &&
        key !== "fpSprites" &&
        key !== "fpSprite"
      ) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  };

  const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key: string, value: any) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

  const updateLocalStorageKey = (state: any) => {
    const storedState = JSON.parse(
      localStorage.getItem(localStorageKey) as any
    );
    const newState = { ...storedState, ...state };
    localStorage.setItem(localStorageKey, JSON.stringify(newState));
  };

  const initialState = {
    ...InitialState,
    ...storedState,
  };

  return {
    ...initialState,
    next: () => {
      set((state) => {
        const nextIndex = (state.currentIndex + 1) % state.cameraViews.length;
        return { currentIndex: nextIndex };
      });
    },
    prev: () => {
      set((state) => {
        let prevIndex = state.currentIndex - 1;
        if (prevIndex < 0) {
          prevIndex = state.cameraViews.length - 1;
        }
        return { currentIndex: prevIndex };
      });
    },
    openModal: () => set({ modalIsOpen: true }),
    closeModal: () => set({ modalIsOpen: false }),
    setGameData: (newState) => {
      set((state) => {
        const updatedState = {
          ...state,
          gameData: { ...state.gameData, ...newState },
        };

        try {
          updateLocalStorage(updatedState);
        } catch (e) {
          console.log(e);
        }
        console.log("updatedState", updatedState);
        return updatedState;
      });
    },
    setCameraViews: (cameraViews: Camera[]) =>
      set((state) => ({ ...state, cameraViews })),
    setActiveGames: (activeGames: Games[]) =>
      set((state) => ({ ...state, activeGames })),
    setActiveSprites: (fpSprites: FPSprites[]) =>
      set((state) => ({ ...state, fpSprites })),
    setActiveModels: (fpModel: any) =>
      set((state) => {
        const currentModels = state.fpModels;
        const fpModels = [...state.fpModels, fpModel];
        return { ...state, fpModels };
      }),
    setActiveGame: (activeGame: Game) =>
      set((state) => ({ ...state, activeGame })),
    setActiveSprite: (fpSprite: FPSprite): any =>
      set((state) => ({ ...state, fpSprite })),
    setInactiveGames: (inactiveGames: Games[]) =>
      set((state) => ({ ...state, inactiveGames })),
    setEnableRoot: (enabledRoot: boolean) =>
      set((state) => {
        return { ...state, enabledRoot };
      }),
    setDisableLoader: (disableLoader: boolean) =>
      set((state) => ({ ...state, disableLoader })),
    setDisplayCover: (displayCover: boolean) =>
      set((state) => ({ ...state, displayCover })),
    setRenderInc: (renderInc: number) =>
      set((state) => ({ ...state, renderInc })),
    setRenderPixi: (renderPixi: boolean) =>
      set((state) => {
        if (state.stage && !renderPixi) {
          const stage = Object.assign({}, state.stage);
          console.log(stage);
          if (stage) {
            stage.children = [];
          }
        } else {
          if (state.stage && !renderPixi) {
            const stage = Object.assign({}, state.stage);
            if (stage) {
              //stage.mount();
            }
          }
        }
        return { ...state, renderPixi };
      }),
    setStage: (stage: any) => set((state) => ({ ...state, stage })),
  };
};

export interface AppSlice {
  currentIndex: number;
  cameraViews: Camera[];
  stage: any;
  fpSprite: FPSprite | null;
  activeGame: Game | null;
  inactiveGames: Games[] | null;
  activeGames: Games[] | null;
  fpSprites: FPSprites[] | null;
  fpModels: any[] | null;
  modalIsOpen: boolean;
  gameData: GameData | null;
  enabledRoot: boolean;
  disableLoader: boolean;
  renderPixi: boolean;
  displayCover: boolean;
  renderInc: number;
  setActiveGames: (game: Games[]) => void;
  setActiveSprites: (fpSprites: FPSprites[]) => void;
  setActiveModels: (fpModles: any) => void;
  setInactiveGames: (inactiveGames: Games[]) => void;
  setActiveGame: (game: Game) => void;
  setActiveSprite: (fpSprite: FPSprite) => any;
  next: () => void;
  prev: () => void;
  setCameraViews: (cameraViews: Camera[]) => void;
  setGameData: (newState: Partial<GameData>) => void;
  setEnableRoot: (enabledRoot: boolean) => void;
  setDisableLoader: (disableLoader: boolean) => void;
  setRenderPixi: (renderPixi: boolean) => void;
  setStage: (stage: any) => void;
  setDisplayCover: (displayCover: boolean) => void;
  setRenderInc: (renderInc: number) => void;
}

export interface GameData {
  items: any[];
  key: boolean;
  level: number;
}
