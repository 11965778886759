import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";

interface FadeBounceProps {
  children: React.ReactNode;
}

const FadeBounce: React.FC<FadeBounceProps> = ({ children }) => {
  const childRef = useRef(null);

  useEffect(() => {
    // GSAP timeline for animations
    const timeline = gsap.timeline();

    // Initial state (opacity: 0, translateY: 20px)
    gsap.set(childRef.current, { opacity: 0, y: 40 });

    // Animation sequence: Fade in and bounce
    timeline.to(childRef.current, {
      opacity: 1,
      y: 0,
      duration: 0.5,
      ease: "bounce.out",
    });

    // You can customize the animations based on your requirements

    // Cleanup GSAP animations on component unmount
    return () => {
      timeline.kill();
    };
  }, []);

  return <div ref={childRef}>{children}</div>;
};

export default FadeBounce;
