import React from "react";
import { Tween, Timeline } from "react-gsap";
import styled from "styled-components";

const LogoContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  z-index: 1;
  position: absolute;
  flex: 0;
  display: flex;
  justify-content: center;
  opacity: 0.5;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  transform: scale(0);
`;

const Logo = styled.img`
  width: 40vh;
`;

export interface LogoRotatorProps {
  image: string;
}

const LogoRotator: any = (props: LogoRotatorProps) => {
  let imgLogo = props.image ? props.image : "./images/logo.svg";
  return (
    <Timeline
      target={
        <LogoContainer>
          <Logo src={imgLogo} />
        </LogoContainer>
      }
    >
      <Tween to={{ top: 0, opacity: 1, flex: 1 }} />
      <Tween to={{ scale: 1 }} delay={0.5} />
    </Timeline>
  );
};

export default LogoRotator;
