import { saveAs } from 'file-saver';
import { Scene, SceneLoader } from '@babylonjs/core';
//import { GLTFExporter } from '@babylonjs/loaders/glTF/2.0/glTFLoader'
import { Engine } from 'noa-engine';
import globals from "@utils/globals";
const { isDebug } = globals;


/*
// Function to export the scene as a glTF file
const exportSceneAsGLTF = (scene: Scene, fileName: string) => {
  // Create a glTF serializer
  const gltfSerializer = new GLTFExporter();

  // Serialize the scene to glTF data
  gltfSerializer.parse(scene, scene.getTransformNodesByTags('exportable'), (glTFData) => {
    // Create a Blob from the glTF data
    const glTFBlob = new Blob([gltfSerializer.glTFToBlob(glTFData)], {
      type: 'model/gltf-binary',
    });

    // Save the Blob as a file
    saveAs(glTFBlob, `${fileName}.glb`);
  });
};
*/

// Function to export the voxel data as a .vox file
const exportVoxelData = (fileName: string, noa: Engine) => {
   // const scene = noa.rendering.getScene();
   // exportSceneAsGLTF(scene, fileName);
    /*
    // Get the dimensions of the world
    
    const world: any = noa.world;
    if(isDebug){
        (window as any).world = world;
        (window as any).noa = noa;
    }
    // Get the chunk size
    const chunkSize = world._chunkSize;
  
    // Find the maximum chunk coordinates
    let maxChunkX = -Infinity;
    let maxChunkY = -Infinity;
    let maxChunkZ = -Infinity;
  
    for (const chunk of world._chunksKnown) {
      const [x, y, z] = world.getChunkCoordinates(chunk);
      maxChunkX = Math.max(maxChunkX, x);
      maxChunkY = Math.max(maxChunkY, y);
      maxChunkZ = Math.max(maxChunkZ, z);
    }
  
    // Calculate the dimensions of the world
    const worldWidth = (maxChunkX + 1) * chunkSize;
    const worldHeight = (maxChunkY + 1) * chunkSize;
    const worldDepth = (maxChunkZ + 1) * chunkSize;
  
    // Create a 3D array to store the world data
    const worldData = new Uint8Array(worldWidth * worldHeight * worldDepth);
  
    // Iterate over all chunks and populate the world data
    for (let x = 0; x <= maxChunkX; x++) {
      for (let y = 0; y <= maxChunkY; y++) {
        for (let z = 0; z <= maxChunkZ; z++) {
          const chunkCoords = [x, y, z];
          const chunk = world.getChunkAtCoord(chunkCoords);
          const chunkData = world.getChunkData(chunk);
  
          for (let i = 0; i < chunkSize; i++) {
            for (let j = 0; j < chunkSize; j++) {
              for (let k = 0; k < chunkSize; k++) {
                const voxelID = chunkData.get(i, j, k);
                const worldIndex =
                  (x * chunkSize + i) +
                  (y * chunkSize + j) * worldWidth +
                  (z * chunkSize + k) * worldWidth * worldHeight;
                worldData[worldIndex] = voxelID;
              }
            }
          }
        }
      }
    }
  
    // Convert the world data to a buffer in the .vox format
    const voxBuffer = new ArrayBuffer(268 + worldWidth * worldHeight * worldDepth);
    const voxDataView = new DataView(voxBuffer);
  
    // Write the header information
    voxDataView.setUint32(0, 542327888, true); // Magic number for .vox files
    voxDataView.setUint32(4, 150, true); // Version number
    voxDataView.setUint32(8, 0, true); // Chunk data size (to be updated later)
    voxDataView.setUint32(12, worldWidth, true); // Width
    voxDataView.setUint32(16, worldHeight, true); // Height
    voxDataView.setUint32(20, worldDepth, true); // Depth
  
    // Write the voxel data
    for (let i = 0; i < worldData.length; i++) {
      voxDataView.setUint8(268 + i, worldData[i]);
    }
  
    // Update the chunk data size in the header
    voxDataView.setUint32(8, worldData.length, true);
  
    // Create a Blob from the buffer and save it as a file
    const blob = new Blob([voxBuffer], { type: 'application/octet-stream' });
    saveAs(blob, `${fileName}.vox`);
    */
  }

export default exportVoxelData;