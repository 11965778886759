import React from "react";
import { Tween, Timeline } from "react-gsap";
import styled from "styled-components";

const FabContainer = styled.div`
    position: absolute;
    z-index: 1;
    right: -100px;
    bottom: 15px;
    scale(0);
    opacity: 0;
    cursor:pointer;
`;

const ButtonImg = styled.img`
  height: 15vw;
  max-height: 65px;
`;

const ButtonImgContainer = styled.div``;

export interface FabButtonProps {
  image: string;
  onClick: any;
}

const FabButton: any = (props: FabButtonProps) => {
  let imgLogo = props.image ? props.image : "./images/icons/anchor-icon.svg";
  return (
    <Timeline
      target={
        <FabContainer onClick={props.onClick}>
          <ButtonImgContainer>
            <ButtonImg src={imgLogo} />
          </ButtonImgContainer>
        </FabContainer>
      }
    >
      <Tween
        to={{ opacity: 1, scale: 1, right: 15, bottom: 15, rotation: 360 }}
        delay={1}
        duration={1}
        ease="elastic.out(0.9, 0.1)"
      />
    </Timeline>
  );
};

export default FabButton;
