import React from "react";
import styled from "styled-components";
import { Tween, Timeline } from "react-gsap";
import CV from "@ui/svg/nav/cv";
import LinkedIn from "@ui/svg/nav/linkedin";
import GitHub from "@ui/svg/nav/github";
import FlipBoard from "@ui/svg/nav/flipboard";
import hubs from "@ui/svg/nav/hubs";

const NavContainer = styled.div`
  width: 160px;
  display: flex;
  justify-content: center;
  background-color: white;
  border-radius: 5px;
  margin: auto;
  @media screen and (orientation: portrait) {
    width: 60vw;
  }
  @media screen and (orientation: landscape) {
    width: 260px;
  }
  @media (min-width: 1025px) {
    width: 260px;
  }
`;

const NavBar = styled.div`
  position: fixed;
  width: 100%;
  bottom: -200px;
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  opacity: 0;
`;

const NavLink = styled.a`
  color: white;
  display: flex;
  padding: 5px;
  cursor: pointer;
`;

const NavImg = styled.div`
  color: white;
  height: 8.5vw;
  width: 8.5vw;
  transition: all 0.9ms ease-in;
  &:svg #mainPath: {
    fill: #000066;
  }
  @media screen and (orientation: landscape) {
    height: 40px;
    width: 40px;
  }
  @media (min-width: 1025px) {
    height: 40px;
    width: 40px;
  }
  &:hover,
  :active {
    transform: scale(1.2);
  }
  &:hover {
    fill-rule: evenodd;
  }
`;

const NavBarMenu = (props: any) => {
  return (
    <Timeline
      target={
        <NavBar>
          <NavContainer>
            <NavLink
              href="https://flipboard.com/@seacloud9/interactive-news-that-inspires-creative-engineers-bpgud1suy"
              target="_blank"
            >
              <NavImg dangerouslySetInnerHTML={{ __html: FlipBoard() }} />
            </NavLink>
            <NavLink href="https://github.com/seacloud9" target="_blank">
              <NavImg dangerouslySetInnerHTML={{ __html: GitHub() }} />
            </NavLink>
            <NavLink
              href="https://www.linkedin.com/in/brendonsmith/"
              target="_blank"
            >
              <NavImg dangerouslySetInnerHTML={{ __html: LinkedIn() }} />
            </NavLink>
            <NavLink href="/Brendon-Smith-Resume.pdf" target="_blank">
              <NavImg dangerouslySetInnerHTML={{ __html: CV() }} />
            </NavLink>
          </NavContainer>
        </NavBar>
      }
    >
      <Tween
        to={{ scale: 1, bottom: 15, opacity: 1 }}
        stagger={0.3}
        ease="elastic.out(0.3, 0.1)"
      />
    </Timeline>
  );
};

export default NavBarMenu;
