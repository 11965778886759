import { Effect, ShaderMaterial } from "@babylonjs/core";
// register shader toy fragment example

Effect.ShadersStore.plasmaVertextShader = `
// Vertex shader
precision highp float;

//Attributes
attribute vec3 position;


//Uniforms
uniform mat4 u_world;
uniform mat4 u_viewProjection;
uniform vec3 u_;
uniform float u_Time;
uniform float u_1;
uniform float u_amp;
uniform float u_Structure;
uniform vec3 u_2;
uniform float u_amp1;
uniform float u_Structure1;
uniform vec3 u_3;
uniform float u_amp2;
uniform float u_Structure2;
uniform float u_4;
uniform float u_amp3;
uniform float u_Structure3;
uniform vec3 u_5;
uniform float u_amp4;
uniform float u_Structure4;
uniform vec3 u_6;
uniform float u_amp5;
uniform float u_Structure5;
uniform float u_7;
uniform float u_amp6;
uniform float u_Structure6;


//Varyings
varying vec3 v_position;




//Entry point
void main(void) {

//worldPos
vec4 output1 = u_world * vec4(position, 1.0);

//worldPos * viewProjectionTransform
vec4 output0 = u_viewProjection * output1;

//vertexOutput
gl_Position = output0;
v_position = position;

}

// Fragment shader
precision highp float;

//Uniforms
uniform mat4 u_world;
uniform mat4 u_viewProjection;
uniform vec3 u_;
uniform float u_Time;
uniform float u_1;
uniform float u_amp;
uniform float u_Structure;
uniform vec3 u_2;
uniform float u_amp1;
uniform float u_Structure1;
uniform vec3 u_3;
uniform float u_amp2;
uniform float u_Structure2;
uniform float u_4;
uniform float u_amp3;
uniform float u_Structure3;
uniform vec3 u_5;
uniform float u_amp4;
uniform float u_Structure4;
uniform vec3 u_6;
uniform float u_amp5;
uniform float u_Structure5;
uniform float u_7;
uniform float u_amp6;
uniform float u_Structure6;


//Varyings
varying vec3 v_position;


// SimplexPerlin3D
const float SKEWFACTOR = 1.0/3.0;
const float UNSKEWFACTOR = 1.0/6.0;
const float SIMPLEX_CORNER_POS = 0.5;
const float SIMPLEX_TETRAHADRON_HEIGHT = 0.70710678118654752440084436210485;
float SimplexPerlin3D( vec3 P ){
    P *= SIMPLEX_TETRAHADRON_HEIGHT;
    vec3 Pi = floor( P + dot( P, vec3( SKEWFACTOR) ) );    vec3 x0 = P - Pi + dot(Pi, vec3( UNSKEWFACTOR ) );
    vec3 g = step(x0.yzx, x0.xyz);
    vec3 l = 1.0 - g;
    vec3 Pi_1 = min( g.xyz, l.zxy );
    vec3 Pi_2 = max( g.xyz, l.zxy );
    vec3 x1 = x0 - Pi_1 + UNSKEWFACTOR;
    vec3 x2 = x0 - Pi_2 + SKEWFACTOR;
    vec3 x3 = x0 - SIMPLEX_CORNER_POS;
    vec4 v1234_x = vec4( x0.x, x1.x, x2.x, x3.x );
    vec4 v1234_y = vec4( x0.y, x1.y, x2.y, x3.y );
    vec4 v1234_z = vec4( x0.z, x1.z, x2.z, x3.z );
    Pi.xyz = Pi.xyz - floor(Pi.xyz * ( 1.0 / 69.0 )) * 69.0;
    vec3 Pi_inc1 = step( Pi, vec3( 69.0 - 1.5 ) ) * ( Pi + 1.0 );
    vec4 Pt = vec4( Pi.xy, Pi_inc1.xy ) + vec2( 50.0, 161.0 ).xyxy;
    Pt *= Pt;
    vec4 V1xy_V2xy = mix( Pt.xyxy, Pt.zwzw, vec4( Pi_1.xy, Pi_2.xy ) );
    Pt = vec4( Pt.x, V1xy_V2xy.xz, Pt.z ) * vec4( Pt.y, V1xy_V2xy.yw, Pt.w );
    const vec3 SOMELARGEFLOATS = vec3( 635.298681, 682.357502, 668.926525 );
    const vec3 ZINC = vec3( 48.500388, 65.294118, 63.934599 );
    vec3 lowz_mods = vec3( 1.0 / ( SOMELARGEFLOATS.xyz + Pi.zzz * ZINC.xyz ) );
    vec3 highz_mods = vec3( 1.0 / ( SOMELARGEFLOATS.xyz + Pi_inc1.zzz * ZINC.xyz ) );
    Pi_1 = ( Pi_1.z < 0.5 ) ? lowz_mods : highz_mods;
    Pi_2 = ( Pi_2.z < 0.5 ) ? lowz_mods : highz_mods;
    vec4 hash_0 = fract( Pt * vec4( lowz_mods.x, Pi_1.x, Pi_2.x, highz_mods.x ) ) - 0.49999;
    vec4 hash_1 = fract( Pt * vec4( lowz_mods.y, Pi_1.y, Pi_2.y, highz_mods.y ) ) - 0.49999;
    vec4 hash_2 = fract( Pt * vec4( lowz_mods.z, Pi_1.z, Pi_2.z, highz_mods.z ) ) - 0.49999;
    vec4 grad_results = inversesqrt( hash_0 * hash_0 + hash_1 * hash_1 + hash_2 * hash_2 ) * ( hash_0 * v1234_x + hash_1 * v1234_y + hash_2 * v1234_z );
    const float FINAL_NORMALIZATION = 37.837227241611314102871574478976;
    vec4 kernel_weights = v1234_x * v1234_x + v1234_y * v1234_y + v1234_z * v1234_z;
    kernel_weights = max(0.5 - kernel_weights, 0.0);
    kernel_weights = kernel_weights*kernel_weights*kernel_weights;
    return dot( kernel_weights, grad_results ) * FINAL_NORMALIZATION;
}

`;

Effect.ShadersStore.plasmaFragmentShader = `

//fragmentOutput
#include<helperFunctions>



//Entry point
void main(void) {

//Add
vec3 output27 = v_position + u_;

//Scale
float output40 = u_Time * u_1;

//VectorMerger
vec3 xyz5 = vec3(output40, output40, output40);

//Add
vec3 output39 = v_position + xyz5;

//Remap
vec3 output38 = 0.0 + (output39 - 0.0) * (1.0 - 0.0) / (1.0 - 0.0);

//SimplexPerlin3D
float output37 = SimplexPerlin3D(output38);

//Multiply
float output36 = output37 * u_amp;

//Scale
vec3 output43 = output38 * u_Structure;

//SimplexPerlin3D
float output42 = SimplexPerlin3D(output43);

//Multiply
float output44 = u_amp * u_amp;

//Multiply
float output41 = output42 * output44;

//Add
float output35 = output36 + output41;

//Scale
vec3 output47 = output43 * u_Structure;

//SimplexPerlin3D
float output46 = SimplexPerlin3D(output47);

//Multiply
float output48 = output44 * u_amp;

//Multiply
float output45 = output46 * output48;

//Add
float output34 = output35 + output45;

//Scale
vec3 output51 = output47 * u_Structure;

//SimplexPerlin3D
float output50 = SimplexPerlin3D(output51);

//Multiply
float output52 = output48 * u_amp;

//Multiply
float output49 = output50 * output52;

//Add
float output33 = output34 + output49;

//Scale
vec3 output55 = output51 * u_Structure;

//SimplexPerlin3D
float output54 = SimplexPerlin3D(output55);

//Multiply
float output56 = output52 * u_amp;

//Multiply
float output53 = output54 * output56;

//Add
float output32 = output33 + output53;

//Scale
vec3 output59 = output55 * u_Structure;

//SimplexPerlin3D
float output58 = SimplexPerlin3D(output59);

//Multiply
float output60 = output56 * u_amp;

//Multiply
float output57 = output58 * output60;

//Add
float output31 = output32 + output57;

//Scale
vec3 output63 = output59 * u_Structure;

//SimplexPerlin3D
float output62 = SimplexPerlin3D(output63);

//Multiply
float output64 = output60 * u_amp;

//Multiply
float output61 = output62 * output64;

//Add
float output30 = output31 + output61;

//Scale
vec3 output67 = output63 * u_Structure;

//SimplexPerlin3D
float output66 = SimplexPerlin3D(output67);

//Multiply
float output68 = output64 * u_amp;

//Multiply
float output65 = output66 * output68;

//qx
float output29 = output30 + output65;

//Add
vec3 output79 = v_position + u_2;

//Remap
vec3 output78 = 0.0 + (output79 - 0.0) * (1.0 - 0.0) / (1.0 - 0.0);

//SimplexPerlin3D
float output77 = SimplexPerlin3D(output78);

//Multiply
float output76 = output77 * u_amp1;

//Scale
vec3 output82 = output78 * u_Structure1;

//SimplexPerlin3D
float output81 = SimplexPerlin3D(output82);

//Multiply
float output83 = u_amp1 * u_amp1;

//Multiply
float output80 = output81 * output83;

//Add
float output75 = output76 + output80;

//Scale
vec3 output86 = output82 * u_Structure1;

//SimplexPerlin3D
float output85 = SimplexPerlin3D(output86);

//Multiply
float output87 = output83 * u_amp1;

//Multiply
float output84 = output85 * output87;

//Add
float output74 = output75 + output84;

//Scale
vec3 output90 = output86 * u_Structure1;

//SimplexPerlin3D
float output89 = SimplexPerlin3D(output90);

//Multiply
float output91 = output87 * u_amp1;

//Multiply
float output88 = output89 * output91;

//Add
float output73 = output74 + output88;

//Scale
vec3 output94 = output90 * u_Structure1;

//SimplexPerlin3D
float output93 = SimplexPerlin3D(output94);

//Multiply
float output95 = output91 * u_amp1;

//Multiply
float output92 = output93 * output95;

//Add
float output72 = output73 + output92;

//Scale
vec3 output98 = output94 * u_Structure1;

//SimplexPerlin3D
float output97 = SimplexPerlin3D(output98);

//Multiply
float output99 = output95 * u_amp1;

//Multiply
float output96 = output97 * output99;

//Add
float output71 = output72 + output96;

//Scale
vec3 output102 = output98 * u_Structure1;

//SimplexPerlin3D
float output101 = SimplexPerlin3D(output102);

//Multiply
float output103 = output99 * u_amp1;

//Multiply
float output100 = output101 * output103;

//Add
float output70 = output71 + output100;

//Scale
vec3 output106 = output102 * u_Structure1;

//SimplexPerlin3D
float output105 = SimplexPerlin3D(output106);

//Multiply
float output107 = output103 * u_amp1;

//Multiply
float output104 = output105 * output107;

//qy
float output69 = output70 + output104;

//Add
vec3 output118 = v_position + u_3;

//Remap
vec3 output117 = 0.0 + (output118 - 0.0) * (1.0 - 0.0) / (1.0 - 0.0);

//SimplexPerlin3D
float output116 = SimplexPerlin3D(output117);

//Multiply
float output115 = output116 * u_amp2;

//Scale
vec3 output121 = output117 * u_Structure2;

//SimplexPerlin3D
float output120 = SimplexPerlin3D(output121);

//Multiply
float output122 = u_amp2 * u_amp2;

//Multiply
float output119 = output120 * output122;

//Add
float output114 = output115 + output119;

//Scale
vec3 output125 = output121 * u_Structure2;

//SimplexPerlin3D
float output124 = SimplexPerlin3D(output125);

//Multiply
float output126 = output122 * u_amp2;

//Multiply
float output123 = output124 * output126;

//Add
float output113 = output114 + output123;

//Scale
vec3 output129 = output125 * u_Structure2;

//SimplexPerlin3D
float output128 = SimplexPerlin3D(output129);

//Multiply
float output130 = output126 * u_amp2;

//Multiply
float output127 = output128 * output130;

//Add
float output112 = output113 + output127;

//Scale
vec3 output133 = output129 * u_Structure2;

//SimplexPerlin3D
float output132 = SimplexPerlin3D(output133);

//Multiply
float output134 = output130 * u_amp2;

//Multiply
float output131 = output132 * output134;

//Add
float output111 = output112 + output131;

//Scale
vec3 output137 = output133 * u_Structure2;

//SimplexPerlin3D
float output136 = SimplexPerlin3D(output137);

//Multiply
float output138 = output134 * u_amp2;

//Multiply
float output135 = output136 * output138;

//Add
float output110 = output111 + output135;

//Scale
vec3 output141 = output137 * u_Structure2;

//SimplexPerlin3D
float output140 = SimplexPerlin3D(output141);

//Multiply
float output142 = output138 * u_amp2;

//Multiply
float output139 = output140 * output142;

//Add
float output109 = output110 + output139;

//Scale
vec3 output145 = output141 * u_Structure2;

//SimplexPerlin3D
float output144 = SimplexPerlin3D(output145);

//Multiply
float output146 = output142 * u_amp2;

//Multiply
float output143 = output144 * output146;

//Add
float output108 = output109 + output143;

//q
vec3 xyz4 = vec3(output29, output69, output108);

//q
vec3 output28 = xyz4 * u_4;

//Add
vec3 output26 = output27 + output28;

//Remap
vec3 output25 = 0.0 + (output26 - 0.0) * (1.0 - 0.0) / (1.0 - 0.0);

//SimplexPerlin3D
float output24 = SimplexPerlin3D(output25);

//Multiply
float output23 = output24 * u_amp3;

//Scale
vec3 output149 = output25 * u_Structure3;

//SimplexPerlin3D
float output148 = SimplexPerlin3D(output149);

//Multiply
float output150 = u_amp3 * u_amp3;

//Multiply
float output147 = output148 * output150;

//Add
float output22 = output23 + output147;

//Scale
vec3 output153 = output149 * u_Structure3;

//SimplexPerlin3D
float output152 = SimplexPerlin3D(output153);

//Multiply
float output154 = output150 * u_amp3;

//Multiply
float output151 = output152 * output154;

//Add
float output21 = output22 + output151;

//Scale
vec3 output157 = output153 * u_Structure3;

//SimplexPerlin3D
float output156 = SimplexPerlin3D(output157);

//Multiply
float output158 = output154 * u_amp3;

//Multiply
float output155 = output156 * output158;

//Add
float output20 = output21 + output155;

//Scale
vec3 output161 = output157 * u_Structure3;

//SimplexPerlin3D
float output160 = SimplexPerlin3D(output161);

//Multiply
float output162 = output158 * u_amp3;

//Multiply
float output159 = output160 * output162;

//Add
float output19 = output20 + output159;

//Scale
vec3 output165 = output161 * u_Structure3;

//SimplexPerlin3D
float output164 = SimplexPerlin3D(output165);

//Multiply
float output166 = output162 * u_amp3;

//Multiply
float output163 = output164 * output166;

//Add
float output18 = output19 + output163;

//Scale
vec3 output169 = output165 * u_Structure3;

//SimplexPerlin3D
float output168 = SimplexPerlin3D(output169);

//Multiply
float output170 = output166 * u_amp3;

//Multiply
float output167 = output168 * output170;

//Add
float output17 = output18 + output167;

//Scale
vec3 output173 = output169 * u_Structure3;

//SimplexPerlin3D
float output172 = SimplexPerlin3D(output173);

//Multiply
float output174 = output170 * u_amp3;

//Multiply
float output171 = output172 * output174;

//Add
float output16 = output17 + output171;

//Add
vec3 output187 = v_position + u_5;

//Add
vec3 output186 = output187 + output28;

//Add
vec3 output185 = output186 + xyz5;

//Remap
vec3 output184 = 0.0 + (output185 - 0.0) * (1.0 - 0.0) / (1.0 - 0.0);

//SimplexPerlin3D
float output183 = SimplexPerlin3D(output184);

//Multiply
float output182 = output183 * u_amp4;

//Scale
vec3 output190 = output184 * u_Structure4;

//SimplexPerlin3D
float output189 = SimplexPerlin3D(output190);

//Multiply
float output191 = u_amp4 * u_amp4;

//Multiply
float output188 = output189 * output191;

//Add
float output181 = output182 + output188;

//Scale
vec3 output194 = output190 * u_Structure4;

//SimplexPerlin3D
float output193 = SimplexPerlin3D(output194);

//Multiply
float output195 = output191 * u_amp4;

//Multiply
float output192 = output193 * output195;

//Add
float output180 = output181 + output192;

//Scale
vec3 output198 = output194 * u_Structure4;

//SimplexPerlin3D
float output197 = SimplexPerlin3D(output198);

//Multiply
float output199 = output195 * u_amp4;

//Multiply
float output196 = output197 * output199;

//Add
float output179 = output180 + output196;

//Scale
vec3 output202 = output198 * u_Structure4;

//SimplexPerlin3D
float output201 = SimplexPerlin3D(output202);

//Multiply
float output203 = output199 * u_amp4;

//Multiply
float output200 = output201 * output203;

//Add
float output178 = output179 + output200;

//Scale
vec3 output206 = output202 * u_Structure4;

//SimplexPerlin3D
float output205 = SimplexPerlin3D(output206);

//Multiply
float output207 = output203 * u_amp4;

//Multiply
float output204 = output205 * output207;

//Add
float output177 = output178 + output204;

//Scale
vec3 output210 = output206 * u_Structure4;

//SimplexPerlin3D
float output209 = SimplexPerlin3D(output210);

//Multiply
float output211 = output207 * u_amp4;

//Multiply
float output208 = output209 * output211;

//Add
float output176 = output177 + output208;

//Scale
vec3 output214 = output210 * u_Structure4;

//SimplexPerlin3D
float output213 = SimplexPerlin3D(output214);

//Multiply
float output215 = output211 * u_amp4;

//Multiply
float output212 = output213 * output215;

//Add
float output175 = output176 + output212;

//Add
vec3 output227 = v_position + u_6;

//Add
vec3 output226 = output227 + output28;

//Remap
vec3 output225 = 0.0 + (output226 - 0.0) * (1.0 - 0.0) / (1.0 - 0.0);

//SimplexPerlin3D
float output224 = SimplexPerlin3D(output225);

//Multiply
float output223 = output224 * u_amp5;

//Scale
vec3 output230 = output225 * u_Structure5;

//SimplexPerlin3D
float output229 = SimplexPerlin3D(output230);

//Multiply
float output231 = u_amp5 * u_amp5;

//Multiply
float output228 = output229 * output231;

//Add
float output222 = output223 + output228;

//Scale
vec3 output234 = output230 * u_Structure5;

//SimplexPerlin3D
float output233 = SimplexPerlin3D(output234);

//Multiply
float output235 = output231 * u_amp5;

//Multiply
float output232 = output233 * output235;

//Add
float output221 = output222 + output232;

//Scale
vec3 output238 = output234 * u_Structure5;

//SimplexPerlin3D
float output237 = SimplexPerlin3D(output238);

//Multiply
float output239 = output235 * u_amp5;

//Multiply
float output236 = output237 * output239;

//Add
float output220 = output221 + output236;

//Scale
vec3 output242 = output238 * u_Structure5;

//SimplexPerlin3D
float output241 = SimplexPerlin3D(output242);

//Multiply
float output243 = output239 * u_amp5;

//Multiply
float output240 = output241 * output243;

//Add
float output219 = output220 + output240;

//Scale
vec3 output246 = output242 * u_Structure5;

//SimplexPerlin3D
float output245 = SimplexPerlin3D(output246);

//Multiply
float output247 = output243 * u_amp5;

//Multiply
float output244 = output245 * output247;

//Add
float output218 = output219 + output244;

//Scale
vec3 output250 = output246 * u_Structure5;

//SimplexPerlin3D
float output249 = SimplexPerlin3D(output250);

//Multiply
float output251 = output247 * u_amp5;

//Multiply
float output248 = output249 * output251;

//Add
float output217 = output218 + output248;

//Scale
vec3 output254 = output250 * u_Structure5;

//SimplexPerlin3D
float output253 = SimplexPerlin3D(output254);

//Multiply
float output255 = output251 * u_amp5;

//Multiply
float output252 = output253 * output255;

//Add
float output216 = output217 + output252;

//r
vec3 xyz3 = vec3(output16, output175, output216);

//Scale
vec3 output15 = xyz3 * u_7;

//Add
vec3 output14 = output15 + v_position;

//Remap
vec3 output13 = 0.0 + (output14 - 0.0) * (1.0 - 0.0) / (1.0 - 0.0);

//SimplexPerlin3D
float output12 = SimplexPerlin3D(output13);

//Multiply
float output11 = output12 * u_amp6;

//Scale
vec3 output258 = output13 * u_Structure6;

//SimplexPerlin3D
float output257 = SimplexPerlin3D(output258);

//Multiply
float output259 = u_amp6 * u_amp6;

//Multiply
float output256 = output257 * output259;

//Add
float output10 = output11 + output256;

//Scale
vec3 output262 = output258 * u_Structure6;

//SimplexPerlin3D
float output261 = SimplexPerlin3D(output262);

//Multiply
float output263 = output259 * u_amp6;

//Multiply
float output260 = output261 * output263;

//Add
float output9 = output10 + output260;

//Scale
vec3 output266 = output262 * u_Structure6;

//SimplexPerlin3D
float output265 = SimplexPerlin3D(output266);

//Multiply
float output267 = output263 * u_amp6;

//Multiply
float output264 = output265 * output267;

//Add
float output8 = output9 + output264;

//Scale
vec3 output270 = output266 * u_Structure6;

//SimplexPerlin3D
float output269 = SimplexPerlin3D(output270);

//Multiply
float output271 = output267 * u_amp6;

//Multiply
float output268 = output269 * output271;

//Add
float output7 = output8 + output268;

//Scale
vec3 output274 = output270 * u_Structure6;

//SimplexPerlin3D
float output273 = SimplexPerlin3D(output274);

//Multiply
float output275 = output271 * u_amp6;

//Multiply
float output272 = output273 * output275;

//Add
float output6 = output7 + output272;

//Scale
vec3 output278 = output274 * u_Structure6;

//SimplexPerlin3D
float output277 = SimplexPerlin3D(output278);

//Multiply
float output279 = output275 * u_amp6;

//Multiply
float output276 = output277 * output279;

//Add
float output5 = output6 + output276;

//Scale
vec3 output282 = output278 * u_Structure6;

//SimplexPerlin3D
float output281 = SimplexPerlin3D(output282);

//Multiply
float output283 = output279 * u_amp6;

//Multiply
float output280 = output281 * output283;

//Add
float output4 = output5 + output280;

//r
vec3 xyz2 = vec3(output4, output4, output4);

//Remap
vec3 output3 = 0.0 + (xyz2 - -0.5) * (1.0 - 0.0) / (0.5 - -0.5);

//Gradient
vec3 gradientTempColor = vec3(0.00784313725490196, 0.6313725490196078, 0.09411764705882353);
float gradientTempPosition;
gradientTempPosition = clamp((output3.x - 0.12) / (0.37 -  0.12), 0.0, 1.0) * step(1.0, 3.0);
gradientTempColor = mix(gradientTempColor, vec3(0.050980392156862744, 0.9568627450980393, 0.3058823529411765), gradientTempPosition);
gradientTempPosition = clamp((output3.x - 0.37) / (0.7 -  0.37), 0.0, 1.0) * step(2.0, 3.0);
gradientTempColor = mix(gradientTempColor, vec3(0.054901960784313725, 0.5019607843137255, 0.7254901960784313), gradientTempPosition);
gradientTempPosition = clamp((output3.x - 0.7) / (0.96 -  0.7), 0.0, 1.0) * step(3.0, 3.0);
gradientTempColor = mix(gradientTempColor, vec3(0, 0.09019607843137255, 0.9019607843137255), gradientTempPosition);
vec3 output2 = gradientTempColor;

//fragmentOutput
gl_FragColor = vec4(output2, 1.0);
#ifdef CONVERTTOLINEAR0
gl_FragColor = toLinearSpace(gl_FragColor);
#endif
#ifdef CONVERTTOGAMMA0
gl_FragColor = toGammaSpace(gl_FragColor);
#endif

}
`;

Effect.ShadersStore.vaporWaveSynthPixelShader = `
uniform float time;
uniform vec2 mousePos;
uniform vec2 resolution;
varying vec2 vUV;
float jtime;


float amp(vec2 p){
    return smoothstep(1.,8.,abs(p.x));
    
}
float pow512(float a){
    a*=a;//^2
    a*=a;//^4
    a*=a;//^8
    a*=a;//^16
    a*=a;//^32
    a*=a;//^64
    a*=a;//^128
    a*=a;//^256
    return a*a;
}
float pow1d5(float a){
    return a*sqrt(a);
}
float hash21(vec2 co){
    return fract(sin(dot(co.xy,vec2(1.9898,7.233)))*45758.5433);
}
float hash(vec2 uv){
    float a = amp(uv);
    float w = a>0.?(1.-.4*pow512(.51+.49*sin((.02*(uv.y+.5*uv.x)-time)*2.))):0.;
    return a>0.?
        a*pow1d5(
        //texture(iChannel0,uv/iChannelResolution[0].xy).r
        hash21(uv)
        )*w
        :0.;
}

vec2 trinoise(vec2 uv){
    const float sq = sqrt(3./2.);
    uv.x *= sq;
    uv.y -= .5*uv.x;
    vec2 d = fract(uv);
    uv -= d;
    if(dot(d,vec2(1))<1.){
        float n1 = hash(uv);
        float n2 = hash(uv+vec2(1,0));
        float n3 = hash(uv+vec2(0,1));
        float nmid = mix(n2,n3,d.y);
        float ng = mix(n1,n3,d.y);
        float dx = d.x/(1.-d.y);
        return vec2(mix(ng,nmid,dx),min(min((1.-dx)*(1.-d.y),d.x),d.y));
	}else{
    	float n2 = hash(uv+vec2(1,0));
        float n3 = hash(uv+vec2(0,1));
        float n4 = hash(uv+1.);
        float nmid = mix(n2,n3,d.y);
        float nd = mix(n2,n4,d.y);
        float dx = (1.-d.x)/(d.y);
        return vec2(mix(nd,nmid,dx),min(min((1.-dx)*d.y,1.-d.x),1.-d.y));
	}
    return vec2(0);
}


vec2 map(vec3 p){
    vec2 n = trinoise(p.xz);
    return vec2(p.y-2.*n.x,n.y);
}

vec3 grad(vec3 p){
    const vec2 e = vec2(.005,0);
    float a =map(p).x;
    return vec3(map(p+e.xyy).x-a
                ,map(p+e.yxy).x-a
                ,map(p+e.yyx).x-a)/e.x;

}

vec2 intersect(vec3 ro,vec3 rd){
    float d =0.,h=0.;
    for(int i = 0;i<500;i++){
        vec3 p = ro+d*rd;
        vec2 s = map(p);
        h = s.x;
        d+= h*.5;
        if(abs(h)<.003*d)
            return vec2(d,s.y);
        if(d>150.|| p.y>2.) break;
    }
    
    return vec2(-1);
}


vec3 sun(vec3 rd,vec3 ld,vec3 base){
    
	float sun = smoothstep(.21,.2,distance(rd,ld));
    
    if(sun>0.){
        float yd = (rd.y-ld.y);

        float a =sin(3.1*exp(-(yd)*14.)); 

        sun*=smoothstep(-.8,0.,a);

        base = mix(base,vec3(1.,.8,.4)*.75,sun);
    }
    return base;
}
vec3 gsky(vec3 rd,vec3 ld,bool mask){
    float haze = exp2(-5.*(abs(rd.y)-.2*dot(rd,ld)));
    //float st = mask?pow512(texture(iChannel0,(rd.xy+vec2(300.1,100)*rd.z)*10.).r)*(1.-min(haze,1.)):0.;
    float st = mask?pow512(hash21((rd.xy+vec2(300.1,100)*rd.z)*10.))*(1.-min(haze,1.)):0.;
    vec3 col=clamp(mix(vec3(.4,.1,.7),vec3(.7,.1,.4),haze)+st,0.,1.);
    return mask?sun(rd,ld,col):col;
   
}


void main( void )
{
    gl_FragColor=vec4(0);
    #ifdef AA
    for(float x = 0.;x<1.;x+=1./float(AA)){
    for(float y = 0.;y<1.;y+=1./float(AA)){
    #else
        const float AA=1.,x=0.,y=0.;
    #endif
    vec2 uv = (2.*(vUV+vec2(x,y))-resolution.xy)/resolution.x;
    
	//float dt = fract(texture(iChannel0,float(AA)*(vUV+vec2(x,y))/iChannelResolution[0].xy).r+time);
    float dt = fract(hash21(float(AA)*(vUV+vec2(x,y)))+time);
    jtime = mod(time-dt*time*.25,4000.);
    vec3 ro = vec3(0.,1,(-2000.+time)*10.);
    
        #ifdef stereo
            ro+=vec3(.2*(float(uv.x>0.)-.5),0.,0.);
            uv.x=uv.x+.5*(uv.x>0.?-1.:1.);
            uv*=2.;
		#endif
        
    vec3 rd = normalize(vec3(uv,.75));//vec3(uv,sqrt(1.-dot(uv,uv)));
    
    vec2 i = intersect(ro,rd);
    float d = i.x;
    
    vec3 ld = normalize(vec3(0,.125+.05*sin(.1*jtime),1));

    float fog = d>0.?exp2(-d*.14):0.;
    vec3 sky = gsky(rd,ld,d<0.);
    
    vec3 p = ro+d*rd;
    vec3 n = normalize(grad(p));
    
    float diff = dot(n,ld)+.1*n.y;
    vec3 col = vec3(.1,.11,.18)*diff;
    
    vec3 rfd = reflect(rd,n); 
    vec3 rfcol = gsky(rfd,ld,true);
    
    col = mix(col,rfcol,.05+.95*pow(max(1.+dot(rd,n),0.),5.));
    #ifdef VAPORWAVE
    col = mix(col,vec3(.4,.5,1.),smoothstep(.05,.0,i.y));
    col = mix(sky,col,fog);
    col = sqrt(col);
    #else
    col = mix(col,vec3(.8,.1,.92),smoothstep(.05,.0,i.y));
    col = mix(sky,col,fog);
    //no gamma for that old cg look
    #endif
    if(d<0.)
        d=1e6;
    d=min(d,10.);
    gl_FragColor += vec4(clamp(col,0.,1.),d<0.?0.:.1+exp2(-d));
     #ifdef AA
    }
    }
    gl_FragColor/=float(AA*AA);
    #endif
    
}

`;

Effect.ShadersStore.vaporWavePixelShader = `
            uniform float time;
            uniform vec2 mousePos;
            uniform vec2 resolution;
            varying vec2 vUV;
            #define PI 3.1415926535
            #define STEPS 50
            mat2 rot( in float a ) {
                float c = cos(a);
                float s = sin(a);
                return mat2(c,s,-s,c);
            }
            // noise function
            float noise( in vec2 p ) {
                p *= rot(1.941611);
                return sin(p.x) * .25 + sin(p.y) * .25 + .50;
            }
            // get the 2 closest point with the projected height as z
            void grid( in vec2 p, inout vec3 projClosest, inout vec3 projSecondClosest ) {
                vec2 center = floor(p) + 0.5;
                vec2 secondBestCenter = center;
                float secondBestDist = 99999.9;
                vec2 bestCenter = center;
                float bestDist = 99999.9;
                for (int y = -1 ; y <= 1 ; y++)
                for (int x = -1 ; x <= 1 ; x++) {
                    vec2 currentCenter = center + vec2(x, y);
                    // vary each center a bit
                    currentCenter.x += noise(
                        time * vec2(0.5124, 0.5894) +
                        currentCenter * vec2(1.3124, 1.7894)) * 1.0 - 0.5;
                    currentCenter.y += noise(
                        time * vec2(0.5565, 0.5561) -
                        currentCenter * vec2(1.5124, 1.6053)) * 1.0 - 0.5;
                    vec2 delta = p - currentCenter;
                    float currentDist = dot(delta, delta)*0.5;
                    // use an analytical if to avoid the branch
                    float if1 = step(currentDist, bestDist);
                    float if1m = 1.0 - if1;
                    secondBestCenter = if1*bestCenter + if1m*secondBestCenter;
                    secondBestDist = if1*bestDist + if1m*secondBestDist;
                    bestCenter = if1*currentCenter + if1m*bestCenter;
                    bestDist = if1*currentDist + if1m*bestDist;
                    // else if
                    float if2 = step(currentDist, secondBestDist)*if1m;
                    float if2m = 1.0 - if2;
                    secondBestCenter = if2*currentCenter + if2m*secondBestCenter;
                    secondBestDist = if2*currentDist + if2m*secondBestDist;
                }
                projClosest = vec3(bestCenter, bestDist);
                projSecondClosest = vec3(secondBestCenter, secondBestDist);
            }
            // normal function
            vec3 normal( in vec3 p, in vec3 proj ) {
                vec2 dir = proj.xy - p.xy;
                vec3 tang = vec3(dir, proj.z*0.12);
                vec3 nine = vec3(dir, 0).yxz;
                nine.x = -nine.x;
                return normalize(cross(nine, tang));
            }
            // distance function
            float de( in vec3 p, inout vec3 projClosest, inout vec3 projSecondClosest ) {
                // get the closest points
                grid(p.xy, projClosest, projSecondClosest);
                float below = 0.0;
                below -= sin(dot(p.xy, vec2(0.005, 0.051)) * 4.0 + time * 0.5) * 0.4 + 0.2;
                below -= 1.0 - projClosest.z;
                return max(0.0, p.z - below);
            }
            // return the sun color at this direction
            vec4 getSunColor( in vec3 dir, inout float inside ) {
                float dotp = dot(dir, vec3(-0.99, 0.0, 0.1));
                float sunHeight = smoothstep(0.01, 0.29, dir.z);
                inside = smoothstep(0.977, 0.979, dotp);
                float ytemp = abs(dir.y)*dir.y;
                float sunWave = sin(dir.z*300.0+time*1.846+
                                    sin(ytemp*190.0+time*0.45)*1.3)*0.5+0.5;
                float sunHeight2 = smoothstep(-0.1, 0.2, dir.z);
                sunWave = sunWave * sunHeight2 + 1.0 - sunHeight2;
                sunWave = (1.0-smoothstep(sunHeight2, 1.0, sunWave)) * (1.0 - sunHeight2) + sunHeight2;
                float sun = inside * sunWave;
                return vec4(mix(vec3(0.998, 0.108, 0.47), vec3(0.988, 0.769, 0.176), sunHeight), sun);
            }
            // get the space color
            vec3 getSpaceColor( in vec3 dir ) {
                float scanline = sin(dir.z * 700.0 - time * 5.1)*0.5+0.5;
                scanline *= scanline;
                vec3 color = mix(vec3(0.1, 0.16, 0.26), vec3(0.1), scanline);
                vec2 uv = vec2(atan(dir.y, dir.x) / (2.0 * PI) + 0.5, mod(dir.z, 1.0));
                uv.x = mod(uv.x+2.0*PI, 1.0);
                uv.x *= 100.0;
                uv.y *= 15.00;
                uv *= rot(1.941611+time*0.00155);
                vec2 center = floor(uv) + 0.5;
                center.x += noise(center*48.6613) * 0.8 - 0.4;
                center.y += noise(center*-31.1577) * 0.8 - 0.4;
                float radius = smoothstep(0.6, 1.0, noise(center*42.487+
                                                        vec2(0.1514, 0.1355)*time));
                radius *= 0.01;
                vec2 delta = uv-center;
                float dist = dot(delta, delta);
                float frac = 1.0-smoothstep(0.0, radius, dist);
                float frac2 = frac;
                frac2 *= frac2; frac2 *= frac2; frac2 *= frac2;
                vec3 lightColor = mix(vec3(0.988, 0.769, 0.176),
                                    vec3(0.988, 0.434, 0.875), noise(center*74.487));
                return mix(color, lightColor, frac) + vec3(1)*frac2;
            }
            // get the background color (ala cubemap)
            vec3 getBackgroundColor( in vec3 dir ) {
                float horizon = 1.0 - smoothstep(0.0, 0.02, dir.z);
                // this is the background with the scanline
                vec3 color = getSpaceColor(dir);
                // get the sun
                float inside = 0.0;
                vec4 sun = getSunColor(dir, inside);
                color = mix(color, vec3(0.1, 0.16, 0.26), inside);
                color = mix(color, sun.rgb, sun.a);
                // the horizon
                color = mix(color, vec3(0.43, 0.77, 0.85), horizon * (1.0 - sun.a * 0.19));
                return color;
            }
            // the color gets more blue/white near edges of the voronoi cells
            vec3 getWaveColor( in vec3 p, in vec3 projClosest, in vec3 projSecondClosest,
                            in vec3 dir, float dist, vec2 frag ) {
                float distanceToEdge = abs(projClosest.z-projSecondClosest.z);
                float distanceFrac = smoothstep(-10.0, 100.0, dist);
                distanceFrac *= distanceFrac; distanceFrac *= distanceFrac;
                float frac = smoothstep(0.0, 0.1+distanceFrac*0.9, distanceToEdge);
                // get the reflection
                vec3 norm = normal(p, projClosest);
                vec3 color = getBackgroundColor(reflect(dir, norm));
                // add a screenspace scanline
                frac *= (sin(frag.y/resolution.y*700.0)*0.5+0.5)*(1.0-distanceFrac);
                return mix(vec3(0.43, 0.77, 0.85), color, frac);
            }
            void main( void ) {
                vec2 uv = vUV.xy / resolution.xy * 2.0 - 1.0;
                uv.y *= resolution.y / resolution.x;
                vec3 from = vec3(0, 0, 0.2);
                vec3 dir = normalize(vec3(uv.x*0.6, 1.0, uv.y*-0.6));
                dir.xy *= rot(PI*.5);
                vec2 mouse=(mousePos.xy / resolution.xy - 0.5) * 0.5;
                mouse *= step(1.0, mousePos.x);
                dir.xz *= rot(3.16-(-mouse.y*1.5)+sin(time*0.785)*0.008);
                dir.xy *= rot(-mouse.x*4.0+sin(time*0.416)*0.01);
                dir.yz *= rot(sin(time*0.287)*0.009);
                vec3 color = vec3(0);
                if (dir.z > 0.0) {
                    color = getBackgroundColor(dir);
                } else {
                // project the starting position to z = 0 so we ccan lower the raymarch count
                    float totdist = from.z / -dir.z;
                    for (int steps = 0 ; steps < STEPS ; steps++) {
                        vec3 p = from + totdist * dir;
                        vec3 projClosest;
                        vec3 projSecondClosest;
                        p.x -= time * 2.7;
                        float dist = de(p, projClosest, projSecondClosest);
                        totdist += dist;
                        if ( dist < 0.01 || steps == STEPS-1 ) {
                            color = getWaveColor(p, projClosest, projSecondClosest,
                                                dir, totdist, vUV);
                            break;
                        }
                    }
                }
                gl_FragColor = vec4(color, 1);
            }
`;
Effect.ShadersStore.cloudsFragmentShader = `
uniform float time;
uniform vec2 mousePos;
uniform vec2 resolution;
varying vec2 vUV;

const float cloudscale = 1.1;
const float speed = 0.03;
const float clouddark = 0.5;
const float cloudlight = 0.3;
const float cloudcover = 0.2;
const float cloudalpha = 8.0;
const float skytint = 0.5;
const vec3 skycolour1 = vec3(0.2, 0.4, 0.6);
const vec3 skycolour2 = vec3(0.4, 0.7, 1.0);

const mat2 m = mat2( 1.6,  1.2, -1.2,  1.6 );

vec2 hash( vec2 p ) {
	p = vec2(dot(p,vec2(127.1,311.7)), dot(p,vec2(269.5,183.3)));
	return -1.0 + 2.0*fract(sin(p)*43758.5453123);
}

float noise( in vec2 p ) {
    const float K1 = 0.366025404; // (sqrt(3)-1)/2;
    const float K2 = 0.211324865; // (3-sqrt(3))/6;
	vec2 i = floor(p + (p.x+p.y)*K1);	
    vec2 a = p - i + (i.x+i.y)*K2;
    vec2 o = (a.x>a.y) ? vec2(1.0,0.0) : vec2(0.0,1.0); //vec2 of = 0.5 + 0.5*vec2(sign(a.x-a.y), sign(a.y-a.x));
    vec2 b = a - o + K2;
	vec2 c = a - 1.0 + 2.0*K2;
    vec3 h = max(0.5-vec3(dot(a,a), dot(b,b), dot(c,c) ), 0.0 );
	vec3 n = h*h*h*h*vec3( dot(a,hash(i+0.0)), dot(b,hash(i+o)), dot(c,hash(i+1.0)));
    return dot(n, vec3(70.0));	
}

float fbm(vec2 n) {
	float total = 0.0, amplitude = 0.1;
	for (int i = 0; i < 7; i++) {
		total += noise(n) * amplitude;
		n = m * n;
		amplitude *= 0.4;
	}
	return total;
}

// -----------------------------------------------

void main( void ) {
    vec2 p = gl_FragCoord.xy / resolution.xy;
	vec2 uv = p*vec2(resolution.x/resolution.y,1.0);    
    float time = time * speed;
    float q = fbm(uv * cloudscale * 0.5);
    
    //ridged noise shape
	float r = 0.0;
	uv *= cloudscale;
    uv -= q - time;
    float weight = 0.8;
    for (int i=0; i<8; i++){
		r += abs(weight*noise( uv ));
        uv = m*uv + time;
		weight *= 0.7;
    }
    
    //noise shape
	float f = 0.0;
    uv = p*vec2(resolution.x/resolution.y,1.0);
	uv *= cloudscale;
    uv -= q - time;
    weight = 0.7;
    for (int i=0; i<8; i++){
		f += weight*noise( uv );
        uv = m*uv + time;
		weight *= 0.6;
    }
    
    f *= r + f;
    
    //noise colour
    float c = 0.0;
    time = time * speed * 2.0;
    uv = p*vec2(resolution.x/resolution.y,1.0);
	uv *= cloudscale*2.0;
    uv -= q - time;
    weight = 0.4;
    for (int i=0; i<7; i++){
		c += weight*noise( uv );
        uv = m*uv + time;
		weight *= 0.6;
    }
    
    //noise ridge colour
    float c1 = 0.0;
    time = time * speed * 3.0;
    uv = p*vec2(resolution.x/resolution.y,1.0);
	uv *= cloudscale*3.0;
    uv -= q - time;
    weight = 0.4;
    for (int i=0; i<7; i++){
		c1 += abs(weight*noise( uv ));
        uv = m*uv + time;
		weight *= 0.6;
    }
	
    c += c1;
    
    vec3 skycolour = mix(skycolour2, skycolour1, p.y);
    vec3 cloudcolour = vec3(1.1, 1.1, 0.9) * clamp((clouddark + cloudlight*c), 0.0, 1.0);
   
    f = cloudcover + cloudalpha*f*r;
    
    vec3 result = mix(skycolour, clamp(skytint * skycolour + cloudcolour, 0.0, 1.0), clamp(f + c, 0.0, 1.0));
    
	gl_FragColor = vec4( result, 1.0 );
}

`;

Effect.ShadersStore.seaCloudsFragmentShader = `
uniform float time;
uniform vec2 mousePos;
uniform vec2 resolution;
varying vec2 vUV;

            
  float hash( float n ) {
    return fract(sin(n)*43758.5453);
  }
  
  float noise( in vec3 x ) {
    vec3 p = floor(x);
    vec3 f = fract(x);
    f = f*f*(3.0-2.0*f);
    float n = p.x + p.y*57.0 + 113.0*p.z;
    return mix(mix(mix( hash(n+  0.0), hash(n+  1.0),f.x),
                   mix( hash(n+ 57.0), hash(n+ 58.0),f.x),f.y),
               mix(mix( hash(n+113.0), hash(n+114.0),f.x),
                   mix( hash(n+170.0), hash(n+171.0),f.x),f.y),f.z);
  }
  
  vec4 map( in vec3 p ) {
    float d = 0.2 - p.y;
    vec3 q = p - vec3(1.0,0.1,0.0)*time;
    float f;
    f  = 0.5000*noise( q ); q = q*2.02;
    f += 0.2500*noise( q ); q = q*2.03;
    f += 0.1250*noise( q ); q = q*2.01;
    f += 0.0625*noise( q );
    d += 3.0 * f;
    d = clamp( d, 0.0, 1.0 );
    vec4 res = vec4( d );
    res.xyz = mix( 1.15*vec3(1.0,0.95,0.8), vec3(0.7,0.7,0.7), res.x );
    return res;
  }
  
  vec3 sundir = vec3(-1.0,0.0,0.0);
  
  vec4 raymarch( in vec3 ro, in vec3 rd ) {
    vec4 sum = vec4(0, 0, 0, 0);
    float t = 0.0;
    for(int i=0; i<64; i++) {
      if( sum.a > 0.99 ) continue;

      vec3 pos = ro + t*rd;
      vec4 col = map( pos );
		
      #if 1
        float dif =  clamp((col.w - map(pos+0.3*sundir).w)/0.6, 0.0, 1.0 );
        vec3 lin = vec3(0.65,0.68,0.7)*1.35 + 0.45*vec3(0.7, 0.5, 0.3)*dif;
        col.xyz *= lin;
      #endif
		
      col.a *= 0.35;
      col.rgb *= col.a;
      sum = sum + col*(1.0 - sum.a);	

      #if 0
        t += 0.1;
      #else
        t += max(0.1,0.025*t);
      #endif
    }

    sum.xyz /= (0.001+sum.w);
    return clamp( sum, 0.0, 1.0 );
  }
  
  void main(void) {
    vec2 q = gl_FragCoord.xy / resolution.xy;
    vec2 p = -1.0 + 2.0*q;
    p.x *= resolution.x/ resolution.y;
    vec2 mo = -1.0 + 2.0*mousePos.xy / resolution.xy;
    
    // camera
    vec3 ro = 2.0*normalize(vec3(cos(2.75-3.0*mo.x), 0.7+(mo.y+1.0), sin(2.75-3.0*mo.x)));
    vec3 ta = vec3(0.0, 1.0, 0.0);
    vec3 ww = normalize( ta - ro);
    vec3 uu = normalize(cross( vec3(0.0,1.0,0.0), ww ));
    vec3 vv = normalize(cross(ww,uu));
    vec3 rd = normalize( p.x*uu + p.y*vv + 1.5*ww );

	
    vec4 res = raymarch( ro, rd );

    float sun = clamp( dot(sundir,rd), 0.0, 1.0 );
    vec3 col = vec3(0.6,0.71,0.75) - rd.y*0.2*vec3(1.0,0.5,1.0) + 0.15*0.5;
    col += 0.2*vec3(1.0,.6,0.1)*pow( sun, 8.0 );
    col *= 0.95;
    col = mix( col, res.xyz, res.w );
    col += 0.1*vec3(1.0,0.4,0.2)*pow( sun, 3.0 );
  
    gl_FragColor = vec4( col, 1.0 );
  }
`;

Effect.ShadersStore.shaderShiftRGBVertexShader = `
attribute vec3 position;
attribute vec3 normal;
attribute vec2 uv;
uniform mat4 worldViewProjection;
uniform mat4 projection;
uniform float scale;
uniform float shift;
varying vec2 vUV;
        
void main() {
vec3 pos = position;
pos.y = pos.y + ((sin(uv.x * 3.1415926535897932384626433832795) * shift * 5.0) * 0.125);
vUV = uv;
gl_Position = worldViewProjection * vec4(pos,1.);
}`;

Effect.ShadersStore.zoomBlurPixelShader = `
    #ifdef GL_ES
        precision highp float;
    #endif

varying vec2 vUV;
uniform sampler2D textureSampler;
//uniform vec4 filterArea;

uniform vec2 uCenter;
uniform float uStrength;
uniform float uInnerRadius;
uniform float uRadius;

//uniform float MAX_KERNEL_SIZE = 32.0;
//vec4 filterArea = texture2D(textureSampler, vUV);

// author: http://byteblacksmith.com/improvements-to-the-canonical-one-liner-glsl-rand-for-opengl-es-2-0/
highp float rand(vec2 co, float seed) {
    const highp float a = 12.9898, b = 78.233, c = 43758.5453;
    highp float dt = dot(co + seed, vec2(a, b)), sn = mod(dt, 3.14159);
    return fract(sin(sn) * c + seed);
}

void main() {
    vec4 filterArea = texture2D(textureSampler, vUV);
    //vec4 filterArea = vec4(1024.0, 1024.0, 1024.0, 1024.0)
    float MAX_KERNEL_SIZE = 10.0;
    float minGradient = uInnerRadius * 0.3;
    float innerRadius = (uInnerRadius + minGradient * 0.5) / filterArea.x;

    float gradient = uRadius * 0.3;
    float radius = (uRadius - gradient * 0.5) / filterArea.x;

    float countLimit = MAX_KERNEL_SIZE;

    vec2 dir = vec2(uCenter.xy / filterArea.xy - vUV);
    float dist = length(vec2(dir.x, dir.y * filterArea.y / filterArea.x));

    float strength = uStrength;

    float delta = 0.0;
    float gap;
    if (dist < innerRadius) {
        delta = innerRadius - dist;
        gap = minGradient;
    } else if (radius >= 0.0 && dist > radius) { // radius < 0 means it's infinity
        delta = dist - radius;
        gap = gradient;
    }

    if (delta > 0.0) {
        float normalCount = gap / filterArea.x;
        delta = (normalCount - delta) / normalCount;
        countLimit *= delta;
        strength *= delta;
        if (countLimit < 1.0)
        {
            gl_FragColor = texture2D(textureSampler, vUV);
            return;
        }
    }

    // randomize the lookup values to hide the fixed number of samples
    float offset = rand(vUV, 0.0);

    float total = 0.0;
    vec4 color = vec4(0.0);

    dir *= strength;

    for (float t = 0.0; t < MAX_KERNEL_SIZE; t++) {
        float percent = (t + offset) / MAX_KERNEL_SIZE;
        float weight = 4.0 * percent;
        vec2 p = vUV + dir * percent;
        vec4 samplerImg = texture2D(textureSampler, p);

        // switch to pre-multiplied alpha to correctly blur transparent images
        samplerImg.rgb *= samplerImg.a;

        color += samplerImg * weight;
        total += weight;

        if (t > countLimit){
            break;
        }
    }

    color /= total;
    // switch back from pre-multiplied alpha
    color.rgb /= color.a + 0.00001;

    gl_FragColor = color;
}
`;

Effect.ShadersStore.shaderShiftRGBPixelShader = `
      uniform sampler2D imageSampler;
      uniform float hasTexture;
      uniform float shift;
      uniform float scale;
      uniform float opacity;
      uniform vec3 color;
      varying vec2 vUV;
      void main() {
        float angle = 1.55;
        vec2 p = (vUV - vec2(0.5, 0.5)) * (1.0 - scale) + vec2(0.5, 0.5);
        vec2 offset = shift / 4.0 * vec2(cos(angle), sin(angle));
        vec4 cr = texture2D(imageSampler, p + offset);
        vec4 cga = texture2D(imageSampler, p);
        vec4 cb = texture2D(imageSampler, p - offset);
        if (hasTexture == 1.0) gl_FragColor = vec4(cr.r, cga.g, cb.b, cga.a);
        else gl_FragColor = vec4(color, opacity);
      }
`;

export default Effect.ShadersStore;

/*
export const shaderShiftRGBMaterial = (scene) => new ShaderMaterial('shaderShiftRGB', scene, { vertex: 'shaderShiftRGB', fragment: 'shaderShiftRGB' },
  {
    attributes: ['position', 'normal', 'uv'],
    uniforms: ['world', 'worldView', 'worldViewProjection', 'view', 'projection', 'scale', 'shift', 'color', 'opacity', 'texture', 'hasTexture'],
    needAlphaBlending: true,
    needAlphaTesting: true
  })
  */
