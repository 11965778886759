// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ge-header {
  width: 100%;
  grid-column: 2/4;
  grid-row: 1;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 100%;
  background: #333333;
}
#ge-header .command-bar {
  padding-left: 15px;
  grid-row: 1;
  grid-column: 2;
  width: 100%;
}
#ge-header .command-bar .language-button {
  grid-row: 1;
  grid-column: 2;
  color: white;
  width: 144px;
  font-size: 18px;
  text-align: center;
  display: grid;
  align-content: center;
  border-top-left-radius: 14px;
  transform: scale(1);
  user-select: none;
}
#ge-header .command-bar .language-button.active {
  width: 74px;
  cursor: pointer;
  grid-column: 1;
  margin-right: -5px;
  transform: translateX(10px);
}`, "",{"version":3,"sources":["webpack://./../../../tools/guiEditor/dist/scss/header.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,gBAAA;EACA,WAAA;EACA,UAAA;EACA,SAAA;EACA,aAAA;EACA,+BAAA;EACA,wBAAA;EACA,mBAAA;AACJ;AACI;EACI,kBAAA;EACA,WAAA;EACA,cAAA;EACA,WAAA;AACR;AACQ;EACI,WAAA;EACA,cAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,aAAA;EACA,qBAAA;EACA,4BAAA;EACA,mBAAA;EACA,iBAAA;AACZ;AACY;EACI,WAAA;EACA,eAAA;EACA,cAAA;EACA,kBAAA;EACA,2BAAA;AAChB","sourcesContent":["#ge-header {\r\n    width: 100%;\r\n    grid-column: 2 / 4;\r\n    grid-row: 1;\r\n    padding: 0;\r\n    margin: 0;\r\n    display: grid;\r\n    grid-template-columns: auto 1fr;\r\n    grid-template-rows: 100%;\r\n    background: #333333;\r\n\r\n    .command-bar {\r\n        padding-left: 15px;\r\n        grid-row: 1;\r\n        grid-column: 2;\r\n        width: 100%;\r\n\r\n        .language-button {\r\n            grid-row: 1;\r\n            grid-column: 2;\r\n            color: white;\r\n            width: 144px;\r\n            font-size: 18px;\r\n            text-align: center;\r\n            display: grid;\r\n            align-content: center;\r\n            border-top-left-radius: 14px;\r\n            transform: scale(1);\r\n            user-select: none;\r\n\r\n            &.active {\r\n                width: 74px;\r\n                cursor: pointer;\r\n                grid-column: 1;\r\n                margin-right: -5px;\r\n                transform: translateX(10px);\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
