import React from "react";
import { CardContainer } from "../Card/styles";
import {
  Box,
  Container,
  IconButton,
  Link,
  CardContent as MuiCardContent,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import styled from "styled-components";
import GitHubIcon from "@mui/icons-material/GitHub";
import AnchorIcon from "@mui/icons-material/Anchor";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";

export const CardContent = styled(MuiCardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CardBox = styled(Box)`
  padding: 8px;
  font-size: 12px;
`;

export const CardBoxSM = styled(Box)`
  padding: 4px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContactLink: any = styled(Link)<any>`
  text-decoration: none;
  font-size: inherit;
  color: inherit;
`;

const ContactInfo: React.FC<any> = ({ video }) => {
  return (
    <CardContainer>
      <CardContent>
        <CardBoxSM width={"100%"}>Richmond, California</CardBoxSM>
        <CardBox width={"100%"}>
          <Divider variant="middle" />
        </CardBox>
        <CardBoxSM width={"100%"}>
          <ContactLink href="tel://14026608220" target={"_blank"}>
            +1.402.660.8220
          </ContactLink>
        </CardBoxSM>
        <CardBoxSM width={"100%"}>
          <ContactLink
            href="mailto://brendonsmith@seacloud9.org"
            target={"_blank"}
          >
            brendonsmith@seacloud9.org
          </ContactLink>
        </CardBoxSM>
        <CardBoxSM
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <ContactLink
            href="https://calendly.com/brendon-smith"
            target={"_blank"}
          >
            calendly &nbsp;
          </ContactLink>{" "}
          |
          <ContactLink href="/Brendon-Smith-Resume.pdf" target={"_blank"}>
            &nbsp; resume
          </ContactLink>
        </CardBoxSM>
        <CardBoxSM
          width={"100%"}
          style={{ cursor: "pointer" }}
          onClick={() => ((window as any).location = "/experimental")}
        >
          <ContactLink>
            e
            <span
              style={{
                fontFamily: "Rapscallion",
              }}
            >
              *
            </span>
            perimental
          </ContactLink>
        </CardBoxSM>
        <CardBox width={"100%"}>
          <Divider variant="middle" />
        </CardBox>
        <Container
          sx={{
            padding: "0px !important",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <IconButton
            href="https://www.linkedin.com/in/brendonsmith/"
            target="_blank"
            size="small"
          >
            <LinkedInIcon />
          </IconButton>
          <IconButton
            href="https://www.instagram.com/seacloud9/"
            target="_blank"
            size="small"
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            href="https://twitter.com/seacloud9/"
            target="_blank"
            size="small"
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            href="https://www.upwork.com/freelancers/brendonsmith"
            target="_blank"
            size="small"
          >
            <AnchorIcon />
          </IconButton>
          <IconButton
            href="https://github.com/seacloud9"
            target="_blank"
            size="small"
          >
            <GitHubIcon />
          </IconButton>
          <IconButton
            href="https://flipboard.com/@seacloud9/interactive-news-that-inspires-creative-engineers-bpgud1suy"
            target="_blank"
            size="small"
          >
            <AutoStoriesIcon />
          </IconButton>
        </Container>
        {video && video}
      </CardContent>
    </CardContainer>
  );
};

export default ContactInfo;
