import React, { useLayoutEffect, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { useStore } from "@store";
import FabButton from "@ui/GS/FabButton";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
`;

export const ModalContent = styled.div`
  //background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 1rem;
  border-radius: 4px;
  //box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
`;

export const NavFabButton = styled.button`
  position: absolute;
  z-index: 1;
  right: 10px;
  bottom: 10px;
`;
export const CloseButtonInternal = styled.button`
  color: white;
  font-weight: bold;
  cursor: pointer;
  font-size: 3rem;
`;

export const DivRelative = styled.div`
  position: relative;
`;

export const DivModalBtn = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 1rem;
  top: 1rem;
  padding: 0.5rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 3rem;
  cursor: pointer;
  color: white;
  font-weight: bold;
  z-index: 9999;
`;

const createWrapperAndAppendToBody = (wrapperId) => {
  const wrapperElement = document.createElement("div");
  wrapperElement.setAttribute("id", wrapperId);
  document.body.appendChild(wrapperElement);
  return wrapperElement;
};

export const Modal = ({ children }) => {
  const { modalIsOpen, closeModal }: any = useStore();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [closeModal]);

  if (!modalIsOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent onClick={(event) => event.stopPropagation()}>
        <CloseButton onClick={closeModal}>&times;</CloseButton>
        {children}
      </ModalContent>
    </ModalOverlay>
  );
};

export const ModalButton = () => {
  const { openModal }: any = useStore();
  return <FabButton onClick={openModal}>Open Modal</FabButton>;
};

export const ModalContainer = ({
  children,
  wrapperId = "SC9App",
  hasFab = true,
}: any) => {
  return (
    <>
      {hasFab && (
        <DivModalBtn>
          <ModalButton />
        </DivModalBtn>
      )}
      <DivRelative>
        <ReactPortal wrapperId={wrapperId}>
          <Modal>{children}</Modal>
        </ReactPortal>
      </DivRelative>
    </>
  );
};

export const ReactPortal = ({ children, wrapperId = "SC9App" }) => {
  const [wrapperElement, setWrapperElement] = useState(null);

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    // if element is not found with wrapperId or wrapperId is not provided,
    // create and append to body
    if (!element) {
      element = createWrapperAndAppendToBody(wrapperId);
    }
    setWrapperElement(element);
  }, [wrapperId]);

  // wrapperElement state will be null on the very first render.
  if (wrapperElement === null) return null;

  return createPortal(children, wrapperElement);
};
export default ModalContainer;
