import React, {
  useRef,
  useEffect,
  useContext,
  createContext,
  useState,
  useCallback,
} from "react";
import gsap from "gsap";
import { useScene } from "react-babylonjs";
import * as PIXI from "pixi.js";
import { Container, createRoot, Graphics } from "@pixi/react";
import debounce from "debounce";
import { useStore } from "@store";
import SplashScreenOpening from "./SplashScreenOpening";
import IntroSplashScreen from "./IntroSplashScreen";
import LevelUpSplash from "./LevelUpSplash";
import { usePixiData } from "@hooks/usePixi";
import ErrorBoundary from "@screens/ErrorBoundary";
import { gsapTween } from "@utils";
export const StageContext = createContext(null);
let customProceduralTexture = null;
let time = 0;
// fixes android issue
PIXI.settings.PREFER_ENV = 2;
PIXI.settings.CREATE_IMAGE_BITMAP = true;

export const fetchFonts = () => {
  const TreasureFont = new FontFace(
    "Treasuremap",
    `url(/fonts/treasuremap.ttf)`
  );
  return { TreasureFont };
};

export const loadFont = (fontToLoad, loadState) => {
  fontToLoad
    .load()
    .then((loadedFont) => {
      document.fonts.add(loadedFont);
      loadState(true);
    })
    .catch(function (error) {
      console.log("Failed to load font: " + error);
    });
};

export const getSplashFont = (props: any) => {
  return {
    fontFamily: "Treasuremap",
    fontSize: props.splashFontWidth,
    fontWeight: "bold",
    fill: ["#6a11cb", "#2575fc"], // gradient
    stroke: "#4facfe",
    strokeThickness: 2,
    dropShadow: true,
    dropShadowColor: "#000000",
    dropShadowBlur: 4,
    dropShadowAngle: Math.PI / 6,
    dropShadowDistance: 6,
    wordWrap: false,
    wordWrapWidth: props.splashFontWidth * 3,
  };
};

const RenderPIXI = (props) => {
  const { initScene, removeResize, addResize } = usePixiData();
  const { enabledRoot, renderPixi } = useStore();
  const scene = useScene();

  useEffect(() => {
    renderPixi ? addResize() : removeResize();
    return () => {
      removeResize();
    };
  }, [renderPixi]);

  useEffect(() => {
    if (!enabledRoot) {
      initScene();
    }
  }, [enabledRoot]);

  useEffect(() => {
    const observable = scene.onBeforeRenderObservable.add((scene) => {
      if (scene !== null && customProceduralTexture !== null) {
        time += scene.getEngine().getDeltaTime();
        // 'time' is a uniform on the shader
        customProceduralTexture.setFloat("time", time);
      }
    });

    return () => {
      scene.onBeforeRenderObservable.remove(observable);
    };
  });

  return <>{props.children}</>;
};

export const SplashBackground = (props) => {
  const draw = useCallback(
    (g) => {
      g.clear();
      g.beginFill("0xedb3c8ad");
      g.drawRect(props.x, props.y, props.width, props.height);
      g.endFill();
    },
    [props]
  );
  return <Graphics draw={draw} name={"SplashBackground"} />;
};

export const FilterContainer = (props) => {
  const filterContainerRef = useRef(null);
  const filterSize = { x: 20, y: 20 }; // Initial filter size
  let animationId;

  useEffect(() => {
    if (filterContainerRef.current && props.shouldAnimate) {
      const animateFilterSize = () => {
        try {
          filterContainerRef.current.filters[0].size.x = filterSize.x;
          filterContainerRef.current.filters[0].size.y = filterSize.y;
          animationId = requestAnimationFrame(animateFilterSize);
        } catch (e) {}
      };

      gsapTween(() =>
        gsap.to(filterSize, {
          x: 1,
          y: 1,
          duration: 1.2,
          onError: (error) => {
            console.log("filterSize error:", error);
          },
          onUpdate: () => {
            // Start the custom animation loop
            try {
              if (!animationId) {
                animationId = requestAnimationFrame(animateFilterSize);
              }
            } catch (e) {
              console.log("onUpdate:", e);
            }
          },
          onComplete: () => {
            // Stop the custom animation loop
            try {
              //gsap.set(filterSize, { clearProps: "all" });
              cancelAnimationFrame(animationId);
              animationId = null;
              setTimeout(() => props.endStartScreen(), 500);
            } catch (e) {
              console.log("onComplete error", e);
            }
          },
        })
      );
      gsapTween(() =>
        gsap.to(filterContainerRef.current, {
          alpha: 0,
          duration: 1,
          onError: (error) => {
            console.log("filterContainerRef error:", error);
          },
        })
      );
    }

    return () => {
      // Stop the custom animation loop when component unmounts
      if (animationId) {
        cancelAnimationFrame(animationId);
        animationId = null;
      }
    };
  });

  if (!props.name) {
    return null;
  }

  if (props.hasInitalized) {
    return null;
  }
  return (
    <Container {...props} ref={filterContainerRef}>
      {props.children}
    </Container>
  );
};

const PixiSceneContainer0 = (props: any) => {
  return (
    <>
      <IntroSplashScreen {...props} />
      <SplashScreenOpening endStartScreen={() => {}} {...props} />
    </>
  );
};

const PixiSceneContainer1 = (props: any) => {
  props.wipeShaders();
  return <LevelUpSplash {...props} />;
};

export const MapPixiScenes = {
  PixiSceneContainer0: (props) => {
    return (
      <PixiSceneContainer0
        setDisableLoader={props.setDisableLoader}
        {...props.engineProperties}
        resetEngineProperties={props.resetEngineProperties}
      />
    );
  },
  PixiSceneContainer1: (props) => (
    <PixiSceneContainer1
      setDisableLoader={props.setDisableLoader}
      {...props.engineProperties}
      resetEngineProperties={props.resetEngineProperties}
      wipeShaders={props.wipeShaders}
    />
  ),
};

const PixiScene = (props: any) => {
  const { enabledRoot } = useStore();
  return (
    <ErrorBoundary>
      <RenderPIXI
        enableCover={props.enableCover}
        screenOverlay={props.screenOverlay}
        enabledRoot={enabledRoot}
      >
        {props.children}
      </RenderPIXI>
    </ErrorBoundary>
  );
};

export default PixiScene;
