import { Grid, Box, Typography, Chip, Link } from "@mui/material";
import SkillRating from "@muiux/SkillRating";
import ContactInfo from "@muiux/ContactInfo";
import Card from "@muiux/Card";
import { randomItem } from "@utils";

const NavigationColumn = () => {
  const randomAvatar: any = randomItem([
    "brendon-smith-ai0.jpg",
    "brendon-smith-ai1.jpg",
    "brendon-smith-ai2.jpg",
    "brendon-smith-ai3.jpg",
    "brendon-smith-ai4.jpg",
    "brendon-smith-ai0.jpg",
    "brendon-smith-ai5.jpg",
    "brendon-smith-ai7.png",
  ]);
  return (
    <Grid item xs={12} md={4}>
      <Card
        title="Hello"
        avatarImageSrc={`/images/${randomAvatar}`}
        name="Brendon Smith (He/Him)"
        content={
          <Box
            sx={{
              alignItems: "center",
              flexDirection: "column",
              display: "flex",
            }}
          >
            <Typography
              variant="body2"
              sx={{ textTransform: "lowercase", fontWeight: "bold" }}
            >
              Creative | Contributor | Speaker | Leader
            </Typography>
            <Typography
              variant="body2"
              sx={{ textTransform: "lowercase", fontWeight: "bold" }}
            >
              SeaCloud9 Studio
            </Typography>
          </Box>
        }
      />
      <Card
        title="Let's talk"
        toggleSwitch={true}
        cardBackground="#002647f2"
        titleColor={"#fff"}
        content={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: 1,
              color: "#fff",
            }}
          >
            <Link
              href={"https://calendly.com/brendon-smith"}
              target="_blank"
              style={{
                color: "inherit",
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{ textTransform: "lowercase", fontWeight: "bold" }}
              >
                Book your spot&nbsp;
              </Typography>
              <Chip
                sx={{
                  color: "#fff",
                  backgroundColor: "#c32121",
                  fontWeight: "bold",
                }}
                label="calendly"
                variant="outlined"
              />
            </Link>
          </Box>
        }
      />
      <Card
        title="Skills"
        initialToggleState={true}
        content={
          <>
            <SkillRating title="React / React Native" rating={5} />
            <SkillRating title="React Testing Library" rating={5} />
            <SkillRating title="Jest / Cypress" rating={5} />
            <SkillRating title="Storybook" rating={5} />
            <SkillRating title="Headless CMS / Content Stack / WP" rating={5} />
            <SkillRating title="JavaScript / TypeScript" rating={5} />
            <SkillRating title="Elastic Search / SOLR" rating={4.5} />
            <SkillRating title="NOSQL / SQL" rating={4.5} />
            <SkillRating title="GraphQL" rating={5} />
            <SkillRating title="emotionJS / styled-components" rating={5} />
            <SkillRating title="MERN stack" rating={5} />
            <SkillRating title="Design / MUI / ANT" rating={5} />
            <SkillRating title="AWS / Azure / GCP" rating={4} />
            <SkillRating title="Docker / Kubernetes" rating={4} />
            <SkillRating title="CI / GitLab / Jenkins" rating={5} />
            <SkillRating title="Unity3D / Godot" rating={4} />
            <SkillRating title="C#" rating={4.5} />
            <SkillRating title="PHP" rating={3.5} />
            <SkillRating title="threeJS / BabylonJS / 8thWall" rating={5} />
            <SkillRating title="HTML5 / CSS3" rating={5} />
          </>
        }
      />
      <Card
        titleColor="white"
        title="Contact"
        backgroundImage="/images/mui/backgroundMap.png"
        titleBackground="#042966a8"
        content={<ContactInfo />}
      />
    </Grid>
  );
};

export default NavigationColumn;
