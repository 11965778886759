import { longWall, randomCubeWall } from "./utils";

  
export const map0 = [
  [...longWall(10)], // 0
    [randomCubeWall(), randomCubeWall(), 0, 0, 0, 114, 4, 1, randomCubeWall(), randomCubeWall(), ], // 1
    [randomCubeWall(), randomCubeWall(), 0, 0, 0, 0, 0, 3, 0, randomCubeWall()], // 2
    [randomCubeWall(), 0, 0, 0, 0, 0, 0, 0, 0, randomCubeWall()], // 3
    [randomCubeWall(), 0, 0, 0, 0, 0, 2, 0, 0, randomCubeWall()], // 4
    [randomCubeWall(), 3, 0, 0, 2, 0, 0, 113, 0, randomCubeWall()], // 5
    [randomCubeWall(), randomCubeWall(), randomCubeWall(), 0, 0, 0, 0, 0, randomCubeWall(),
    randomCubeWall(), ], // 6
    [randomCubeWall(), randomCubeWall(), randomCubeWall(), 0, 0, 1, 0, 0, 0, randomCubeWall(), ], // 7
    [randomCubeWall(), randomCubeWall(), 0, randomCubeWall(), 0, 0, 0, 3, 0, randomCubeWall(), ], // 8
    [...longWall(4), 18, ...longWall(5)], // 9
  ];

  export const map1 = [
    [...longWall(6), 18, ...longWall(8)], // 0
    [randomCubeWall(), randomCubeWall(), 0, 0, 0, 0, 4, 1, 0, 0, 0, 0, 0, randomCubeWall(), randomCubeWall(), ], // 1
    [randomCubeWall(), randomCubeWall(), 0, 9, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, randomCubeWall()], // 2
    [randomCubeWall(), 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, randomCubeWall()], // 3
    [randomCubeWall(), 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, randomCubeWall()], // 4
    [randomCubeWall(), 3, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, randomCubeWall()], // 5
    [randomCubeWall(), 3, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, randomCubeWall()], // 6
    [randomCubeWall(), 3, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, randomCubeWall()], // 7
    [randomCubeWall(), 3, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, randomCubeWall()], // 8
    [randomCubeWall(), 3, 0, 0, 2, 0, 0, 0, 0, 0, 0, 113, 0, 0, randomCubeWall()], // 9
    [randomCubeWall(), 3, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, randomCubeWall()], // 10
    [randomCubeWall(), randomCubeWall(), randomCubeWall(), 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, randomCubeWall(),
    randomCubeWall(), ], // 11
    [randomCubeWall(), randomCubeWall(), randomCubeWall(), 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, randomCubeWall(), ], // 12
    [randomCubeWall(), randomCubeWall(), 0, randomCubeWall(), 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, randomCubeWall(), ], // 13
    [...longWall(15)], // 14
  ];